import React, { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { OverviewWrapper } from '../pages/overview/DashboardWrapper'
import OverviewWrapperPurchased from '../pages/overview/PurchasedDashboardWrapper'
import { Profile } from '../../app/modules/auth/components/Registration'

export function PrivateRoutes() {
  // const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const Assets = lazy(() => import('../modules/assets/index'))
  const AssetsNew = lazy(() => import('../modules/assets/index_new'))
  const Agreements = lazy(() => import('../modules/agreements/index'))
  const Partners = lazy(() => import('../modules/partner/index'))
  const Suppliers = lazy(() => import('../modules/supplier/index'))
  const Banks = lazy(() => import('../modules/bank/index'))
  const Clients = lazy(() => import('../modules/client/index'))
  const AssetTypes = lazy(() => import('../modules/asset_type/index'))
  const CreateAsset = lazy(() => import('../modules/assets/components/CreateForm'))
  const CreateAgreement = lazy(() => import('../modules/agreements/components/CreateForm'))
  const CreatePartner = lazy(() => import('../modules/partner/components/CreateForm'))
  const CreateSupplier = lazy(() => import('../modules/supplier/components/CreateForm'))
  const CreateBank = lazy(() => import('../modules/bank/components/CreateForm'))
  const CreateClient = lazy(() => import('../modules/client/components/CreateForm'))
  const CreateUser = lazy(() => import('../modules/user/components/CreateForm'))
  const Users = lazy(() => import('../modules/user/index'))
  const Activity = lazy(() => import('../modules/activity/index'))
  const AssetTypes1 = lazy(() => import('../modules/asset_type/indexNew'))
  const PurchasedAssets = lazy(() => import('../modules/assets/purchased_index'))

  return (
    // <Suspense fallback={<FallbackView />}>
    <Suspense fallback={false}>
      <Switch>
        <Route path='/assets' component={Assets} />
        <Route path='/assetsnew' component={Assets} />
        <Route path='/agrementassets/:id' component={Assets} />
        <Route path='/agrementassetsNew/:id' component={Assets} />
        <Route path='/viewassets/:id' component={Assets} />
        <Route path='/agreements' component={Agreements} />
        <Route path='/agreementsview/:year' component={Agreements} />
        <Route path='/createasset' component={CreateAsset} />
        <Route path='/create-leased-asset' component={CreateAsset} />
        <Route path='/createaggreement-leased-asset/:id' component={CreateAsset} />
        <Route path='/createaggreementasset/:id' component={CreateAsset} />
        <Route path='/updateasset/:id' component={CreateAsset} />
        <Route path='/updateassetuser/:id' component={CreateAsset} />
        <Route path='/viewasset/:id' component={CreateAsset} />
        <Route path='/assetview/:type' component={Assets} />
        <Route path='/createagreement' component={CreateAgreement} />
        <Route path='/createownagreement' component={CreateAgreement} />
        <Route path='/updateagreement/:id' component={CreateAgreement} />
        <Route path='/updateownagreement/:id' component={CreateAgreement} />
        <Route path='/partners' component={Partners} />
        <Route path='/ownpartners' component={Partners} />
        <Route path='/createpartner' component={CreatePartner} />
        <Route path='/createownpartner' component={CreatePartner} />
        <Route path='/updatepartner/:id' component={CreatePartner} />
        <Route path='/updateownpartner/:id' component={CreatePartner} />
        <Route path='/suppliers' component={Suppliers} />
        <Route path='/ownsuppliers' component={Suppliers} />
        <Route path='/banks' component={Banks} />
        <Route path='/assettypes' component={AssetTypes1} />
        <Route path='/assettypesnew' component={AssetTypes1} />
        <Route path='/createsupplier' component={CreateSupplier} />
        <Route path='/updatesupplier/:id' component={CreateSupplier} />
        <Route path='/createbank' component={CreateBank} />
        <Route path='/updatebank/:id' component={CreateBank} />
        <Route path='/clients' component={Clients} />
        <Route path='/createclient' component={CreateClient} />
        <Route path='/updateclient/:id' component={CreateClient} />
        <Route path='/users' component={Users} />
        <Route path='/userslist/:id' component={Users} />
        <Route path='/partnersusers/:id' component={Users} />
        <Route path='/supplierusers/:id' component={Users} />
        <Route path='/clientsusers/:id' component={Users} />
        <Route path='/adminusers/:id' component={Users} />
        <Route path='/createuser' component={CreateUser} />
        <Route path='/createclientuser/:id' component={CreateUser} />
        <Route path='/createadminuser/:id' component={CreateUser} />
        <Route path='/updateclientuser/:id' component={CreateUser} />
        <Route path='/createpartneruser/:id' component={CreateUser} />
        <Route path='/updatepartneruser/:id' component={CreateUser} />
        <Route path='/createsupplieruser/:id' component={CreateUser} />
        <Route path='/updatesupplieruser/:id' component={CreateUser} />
        <Route path='/updateadminuser/:id' component={CreateUser} />
        <Route path='/updateuser/:id' component={CreateUser} />
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/overview' component={OverviewWrapper} />
        <Route path='/overview-inventory' component={OverviewWrapperPurchased} />
        <Route path='/inventory' component={PurchasedAssets} />
        <Route path='/inventory-suppliers/:suppliers' component={PurchasedAssets} />
        <Route path='/inventory-equipment/:equipment' component={PurchasedAssets} />
        <Route path='/leased-aggrement' component={Agreements} />
        {/* <Route path='/profile' component={ProfilePage} /> */}
        <Route path='/activity' component={Activity} />
        <Route path='/profile' component={Profile} />

        {/* <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} /> */}
        <Redirect from='/auth' to='/overview' />
        <Redirect from='/login' to='/overview' />
        <Redirect exact from='/' to='/overview' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
