import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useLocation, useParams, Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { resetPassword } from '../redux/AuthCRUD'

const initialValues = {
  email: localStorage.getItem('email') || '',
  password: ''
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState('')
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const location = useLocation().search;
  const urlparams = useParams();


  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        resetPassword(values.password, token)
          .then(({ data }) => {
            setStatus('Password successfully reset')
            setHasErrors(false)
            setLoading(false)
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('Failed to reset password')
          })
      }, 1000)
    },
  })
  useEffect(() => {
    if (location) {
      let currentpage = location.split("?");
      setToken(currentpage[1].split("=")[1])
      console.log(currentpage[1].split("=")[1])
    }
  }, [location]);


  return (
    <>
      {/* <PageLoader pageLoaderOpen={loading} setPageLoaderOpen={setLoading} /> */}
      <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white">
        <div className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10" style={{ background: '#456A76' }}>
          <div className="d-flex flex-row-fluid flex-column justify-content-between">
            <a className="flex-column-auto mt-5 pb-lg-0 pb-10" href="#">
              <img alt="Logo" className="max-h-70px" src="../media/logos/logo-white.png" />
            </a>
            <div className="flex-column-fluid d-flex flex-column justify-content-center">
              <h3 className="font-size-h1 mb-5 text-white">Asset Management System</h3>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
          <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
            <div className="login-form login-signin" id="kt_login_signin_form">
              {/* begin::Heading */}
              {/* begin::Heading */}
              <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_password_reset_form'
                onSubmit={formik.handleSubmit}
              >
                <div className='text-center mb-10'>
                  {/* begin::Title */}
                  {/* end::Title */}
                  <h1 className='text-dark mb-3'>Reset Password</h1>
                  {/* begin::Link */}
                  {/* end::Link */}
                </div>

                {/* begin::Title */}
                {hasErrors === true && (
                  <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>
                      Sorry, looks like there are some errors detected, please try again.
                </div>
                  </div>
                )}

                {formik.status && (
                  <div className='mb-lg-15 alert alert-success'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                  </div>
                )}
                {/* end::Title */}

                {/* begin::Form group */}
                <div className='fv-row mb-10'>
                  <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
                  <input
                    type='email'
                    readOnly={true}
                    placeholder=''
                    autoComplete='off'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      { 'is-invalid': formik.touched.email && formik.errors.email },
                      {
                        'is-valid': formik.touched.email && !formik.errors.email,
                      }
                    )}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.email}</span>
                      </div>
                    </div>
                  )}
                </div>
                {/* end::Form group */}
                {/* begin::Form group */}
                <div className='fv-row mb-10'>
                  <div className='d-flex justify-content-between mt-n5'>
                    <div className='d-flex flex-stack mb-2'>
                    </div>
                  </div>
                  <input
                    type='password'
                    placeholder='password'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.password && formik.errors.password,
                      },
                      {
                        'is-valid': formik.touched.password && !formik.errors.password,
                      }
                    )}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.password}</span>
                      </div>
                    </div>
                  )}
                </div>
                {/* begin::Form group */}
                <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                  <button
                    type='submit'
                    id='kt_password_reset_submit'
                    className='btn btn-lg btn-primary fw-bolder me-4'
                  >
                    <span className='indicator-label'>Submit</span>
                    {loading && (
                      <span className='indicator-progress'>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                  <Link to='/auth/login'>
                    <button
                      type='button'
                      id='kt_login_password_reset_form_cancel_button'
                      className='btn btn-lg btn-light-primary fw-bolder'
                    // disabled={formik.isSubmitting || !formik.isValid}
                    >
                      Signin
            </button>
                  </Link>{' '}
                </div>
                {/* end::Form group */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
