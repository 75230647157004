import axios from 'axios'
import { directUSAPIToken } from '../../../../_metronic/helpers/components/utils';
import {API_URL} from '../../../../_metronic/helpers/components/constant'

export const AGREEMENTS = `${API_URL}/items/aggrement`
export const FILES = `${API_URL}/files`
const headers = {
  'Authorization': `Bearer ${directUSAPIToken()}`
}
// Server should return AuthModel
export function List(queryParam) {
  return axios.get(`${AGREEMENTS}${queryParam}`, {
    params: {},
    headers
  })
}

export function Create(param) {
  delete param.id
  return axios.post(AGREEMENTS, param, { headers })
}
export function UpdateAgreement(param, id) {
  delete param.id
  return axios.patch(`${AGREEMENTS}/${id}`, param, { headers })
}

export function agreementData(queryParam) {
  return axios.get(`${AGREEMENTS}${queryParam}`, {
    params: {},
    headers
  })
}

export function fileupload(files) {
  return axios.post(FILES, files, { headers })
}