import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import { EOL_ACTION } from '../../constant'
import { directUSAPICall, getMonthDiff } from '../../utils';
import { useSelector } from "react-redux";

export default forwardRef((props, ref) => {
    const { data } = useSelector(({ auth }) => auth.user);
    const [selectedValue, setSelectedValue] = useState(props.data?.EOL_action ? props.data?.EOL_action : null);
    const [enable, setEnable] = useState(true);
    const [userInfo, setUserInfo] = useState(data);

    async function handleChange(d) {
        // console.log("*****************", d.target.value)
        setSelectedValue(d.value);
        await directUSAPICall().items('Asset').updateOne(props.data.id, { EOL_action: d.target.value, aggrement: props.data.aggrement.id });

    }

    useEffect(() => {
        // Fetching Asset Typesons
        if (userInfo && props.data?.aggrement?.eol_date) {
            let monthDiff = getMonthDiff(props.data.aggrement.eol_date)
            if (monthDiff <= 3 || userInfo.userType === 'SUPERADMIN') {
                setEnable(false)
            }
        }
    }, [props.data, userInfo]);

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                let dateString = null;
                // if (selectedDate) {
                //     dateString = moment(selectedDate).format('YYYY-MM-DD');
                // }
                return selectedValue;
            },
            isCancelAfterEnd: () => {
                return !selectedValue;
            },
            afterGuiAttached: () => {
                if (!props.value) {
                    return;
                }
                setSelectedValue(props.value);
            }
        };
    });
    return (
        <select
            onChange={handleChange}
            value={selectedValue}
            disabled={enable}
        >
            <option key={'00'} value={""}>
                Please choose
                </option>
            {EOL_ACTION.map((option, index) => (
                option !== 'Please choose' && <option key={index} value={option.value}>
                    {option}
                </option>
            ))}
        </select>
    )
});

