import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import './uikit.less';

const useStyles = makeStyles(theme => ({
	
	button: {
		margin: theme.spacing(1),
	},
}));

export const CustomCancelButton = (props) => {

	const MIclasses = useStyles();

	return <Link to={`/${props.to}`}><Button {...props} className={["cancelBtn", MIclasses.button].join(' ')} >Cancel</Button></Link>;
};

export default CustomCancelButton;
