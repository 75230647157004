import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const ActionCellRenderer = (props) => {
  // console.log("props",props)
  const { data } = useSelector(({ auth }) => auth.user);
  const [userPermission, setUserPermission] = useState({
    canEdit: false,
    canDelete: false,
    canView: false,
    canUserEdit: false
  });
  const [userInfo, setUserInfo] = useState(data);

  useEffect(() => {
    if (userInfo && userInfo.id && userInfo?.role?.name) {
      setUserInfo(userInfo)
    }
  }, [userInfo])
  // console.log("******************** outerrrrr")

  useEffect(() => {
    // if (props && props.agGridReact.props.userPermission) {
    //   setUserPermission(props.agGridReact.props.userPermission)
    // }
  }, [props])

  const deleteConfirm = () => {
    // props.agGridReact.props.deleteConfirm(props.node);
  };

  // const handleView = (type) => {
  //   console.log("propsss", props)
  //   props.agGridReact.props.gotoEdit(props, type);
  // };

  // const handleNotestOpen = () => {
  //   props.agGridReact.props.handleNotestOpen(props);
  // };
  return (
    <div>
      {/* <i className="fas fa-edit" /> */}
      {/* {
        (userPermission && userPermission.canView) && <i onClick={() => handleView('view')} className="fas fa-eye" />
      }
      {
        (props?.node?.data && userPermission && userPermission.canUserEdit) && <i onClick={() => handleView('edit')} className="fas fa-edit" />
      }
      {(!props.agGridReact.props.searchString && userPermission && userPermission.canEdit) && (
        <i onClick={() => handleView('edit')} className="fas fa-edit" />

      )}
      {(props.agGridReact.props.searchString && userPermission && userPermission.canEdit) && (
        <i className="fas fa-edit" onClick={() => handleView('edit')} />
      )}
      <i className="fas fa-sticky-note" onClick={() => handleNotestOpen(props.node)}></i>

       */}
       {(userPermission && userPermission.canDelete) && <i className="fas fa-trash-alt deleteone" onClick={() => deleteConfirm()} />}
    </div>
  );
};

export default ActionCellRenderer;
