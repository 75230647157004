/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { getMonthDiff, directUSAPICall, SVPercentageformatter, SVRemovePercentageformatter, SVformatter } from '../../../../_metronic/helpers/components/utils'
import { getAgreementStatus } from '../../../../_metronic/helpers/components/calculation'
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import { EOL_STATUS, AG_STATUS, PRODUCTCATEGORIES } from "../../../../_metronic/helpers/components/constant"


const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '60%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: '20px'
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}));

export default function Step1({
  setValues,
  values,
  userInfo,
  partnersValues,
  clientsValues,
  suppliersValues,
  // assetTypesValues,
  isDisable,
  hideColumns,
  showFields,
  isClient,
  isPartner,
  isAdmin,
  setErrValues,
  errValues,
  bankLists
}) {
  const classes = useStyles();
  const [clientUsersList, setClientUsersList] = useState([]);
  const [tariffError, setTariffError] = useState('');
  const [clientInterestError, setClientInterestError] = useState('');
  const [supplierUsersList, setSupplierUsersList] = useState([]);
  const [partnerUsersList, setPartnerUsersList] = useState([]);
  const [salespersonUsersList, setsalespersonUsersList] = useState([]);
  const months = [{
    label: '1 month',
    value: 1
  }, {
    label: '2 months',
    value: 2
  }, {
    label: '3 months',
    value: 3
  },
  {
    label: '4 months',
    value: 4
  },
  {
    label: '5 months',
    value: 5
  },
  {
    label: '6 months',
    value: 6
  }];
  // const [selectedEOLUSER, setSelectedEOLUSER] = useState([]);
  // const [selected, setSelected] = useState([]);
  const [value, setValue] = React.useState("auto");

  // useEffect(() => {
  //   if (values.salespersoncustom) {
  //     setValue('custom')
  //   }
  // }, [values.salespersoncustom])

  // useEffect(() => {
  //   if (values.salesperson) {
  //     setValue('auto')
  //   }
  // }, [values.salesperson])

  useEffect(() => {
    if (values.lease_start_date && values.no_of_month_lease) {
      values.eol_date = (getAgreementStatus(values).eoldate) || null;
      let monthDiff = getMonthDiff(values.eol_date);
      let ag_status = (getAgreementStatus(values, monthDiff).status) || "";
      let statusList = ["EXTENDED"]
      // if(!values.ag_status) {
      //   values.ag_status = ag_status
      // }
      if (!statusList.includes(values.ag_status)) {
        values.ag_status = ag_status
      }
      // if (ag_status === values.ag_status) {
      //   values.ag_status = ag_status
      // }
      delete values.status
      setValues({ ...values })
    }
  }, [values.lease_start_date, values.no_of_month_lease])

  const handleChange = name => event => {
    let targetvalues = event.target.value ? event.target.value : '';
    setErrValues({ ...errValues, [`${name}Error`]: targetvalues ? false : true })

    setValues({ ...values, [name]: targetvalues });
  };

  const getClientUsersList = async (id = null) => {
    let options = {
      sort: 'first_name',
      fields: ['id, email,isdefault'],
      filter: {
        _and: [
          {
            "userType": {
              _eq: 'CLIENT'
            }
          },
          {
            "client": {
              _eq: values.client || id
            }
          },
          {
            "status": {
              _eq: 'active'
            }
          }
        ]
      }
    }

    const result = await directUSAPICall().items('directus_users').readMany(options);
    if (result.data && result.data.length > 0) {
      setClientUsersList(result.data)
    }
  }

  const getSupplierUsersList = async (id = null) => {
    let options = {
      sort: 'first_name',
      fields: ['id, email,isdefault'],
      filter: {
        _and: [
          {
            "userType": {
              _eq: 'SUPPLIER'
            }
          },
          {
            "supplier": {
              _eq: values.supplier || id
            }
          },
          {
            "status": {
              _eq: 'active'
            }
          }
        ]
      }
    }

    const result = await directUSAPICall().items('directus_users').readMany(options);
    if (result.data && result.data.length > 0) {
      setSupplierUsersList(result.data)
    }
  }


  const getPartnerUsersList = async (id = null) => {
    let options = {
      sort: 'first_name',
      fields: ['id, email,isdefault'],
      filter: {
        _and: [
          {
            "userType": {
              _eq: 'PARTNER'
            }
          },
          {
            "partner": {
              _eq: values.partner || id
            }
          }, {
            "status": {
              _eq: 'active'
            }
          }
        ]
      }
    }

    const result = await directUSAPICall().items('directus_users').readMany(options);
    if (result.data && result.data.length > 0) {
      setPartnerUsersList(result.data)
    }
  }

  const getSalespersonUsersList = async (id = null) => {
    let options = {
      sort: 'first_name',
      fields: ['id, email,isdefault'],
      filter: {
        _and: [

          {
            "isintensalesperson": {
              _eq: true
            }
          }, {
            "status": {
              _eq: 'active'
            }
          }
        ]
      }
    }

    const result = await directUSAPICall().items('directus_users').readMany(options);
    if (result.data && result.data.length > 0) {
      setsalespersonUsersList(result.data)
    }
  }


  const checkClient = () => {
    if (userInfo && userInfo.role) {
      let disable = false;
      if (userInfo.role.name === 'client_admin' || userInfo.role.name === 'client_user') {
        disable = true
      }
      return disable
    }

  }
  const checkPartner = () => {
    if (userInfo && userInfo.role) {
      let disable = false;
      if (userInfo.role.name === 'partner_admin' || userInfo.role.name === 'partner_user') {
        disable = true
      }
      return disable
    }
  }

  useEffect(() => {
    if (values.client) {
      getClientUsersList(values.client)
    }
  }, [values.client])

  useEffect(() => {
    if (values.partner) {
      getPartnerUsersList(values.partner)
    }
  }, [values.partner])

  useEffect(() => {
    if (values.supplier) {
      getSupplierUsersList(values.supplier)
    }
  }, [values.supplier])

  useEffect(() => {
    getSalespersonUsersList()
  }, [])

  useEffect(() => {
    if (values && values.montly_payment_rent_or_lease && values.sum_purchase_value && SVRemovePercentageformatter(values.montly_payment_rent_or_lease) > 0 && SVRemovePercentageformatter(values.sum_purchase_value) > 0) {
      let tariff = (Number(SVRemovePercentageformatter(values.montly_payment_rent_or_lease)) / Number(SVRemovePercentageformatter(values.sum_purchase_value))) * 100
      values.montly_payment_ratio = (tariff).toFixed(2)
      setValues({ ...values });
    }
  }, [values.montly_payment_rent_or_lease, values.sum_purchase_value])

  useEffect(() => {
    if (values && values.sum_purchase_value_budget && values.invoiced_to_bank && !values.remaining_credit) {
      let remaining_credit = (Number(SVRemovePercentageformatter(values.sum_purchase_value_budget)) - Number(SVRemovePercentageformatter(values.invoiced_to_bank)))
      values.remaining_credit = SVRemovePercentageformatter((remaining_credit).toFixed(2));
      setValues({ ...values });
    }
  }, [values.sum_purchase_value_budget, values.invoiced_to_bank])

  return (
    <div className="card card-custom gutter-b">
      <div className='card-body box-shadow'>
        <div className="form-group row">
          <div className='col-lg-6'>
            {/* <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}

              label="Title"
              id="Title"
              name={'heading'}
              className={classes.textField}
              value={values.heading}
              onChange={handleChange('heading')}
              margin="normal"
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}

            /> */}
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}
              required
              id="Title"
              select
              error={!values.heading ? true : false}
              className={classes.textField}
              value={values.heading}
              name={'heading'}
              onChange={handleChange('heading')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              label="Equipments"
              margin="normal"
            >
              {PRODUCTCATEGORIES.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
              {/* <MenuItem key={'ACCESSORIES'} value="ACCESSORIES">ACCESSORIES</MenuItem>
              <MenuItem key={'COPY/PRINT'} value="COPY/PRINT">COPY/PRINT</MenuItem>
              <MenuItem key={'DESKTOP'} value="DESKTOP">DESKTOP</MenuItem>
              <MenuItem key={'TABLET'} value="TABLET">TABLET</MenuItem>
              <MenuItem key={'LAPTOP'} value="LAPTOP">LAPTOP</MenuItem>
              <MenuItem key={'MONITOR TFT/LCD'} value="MONITOR">MONITOR</MenuItem>
              <MenuItem key={'NETWORK'} value="NETWORK">NETWORK</MenuItem>
              <MenuItem key={'OTHER'} value="OTHER">OTHER</MenuItem>
              <MenuItem key={'STORAGE'} value="STORAGE">STORAGE</MenuItem>
              <MenuItem key={'SMARTPHONE'} value="SMARTPHONE">SMARTPHONE</MenuItem>
              <MenuItem key={'VIDEOCONFERENCE'} value="VIDEOCONFERENCE">VIDEOCONFERENCE</MenuItem>
              <MenuItem key={'FURNITURE'} value="FURNITURE">FURNITURE</MenuItem>
              <MenuItem key={'SOFTWARE'} value="SOFTWARE">SOFTWARE</MenuItem> */}
            </TextField>
            {/* <Autocomplete
                  value={values.heading}
                  name={'heading'}
                  loading={values.heading}
                  id={'supplier'}
                  // defaultValue={suppliersValues.find(v => v.id === )}
                  getOptionLabel={(option) => option && (option.value)}
                  options={[{"name":"ACCESSORIES","value": "ACCESSORIES" }]}
                  disabled={isDisable ? isDisable : false}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={!values.heading ? true : false}
                      label="Equipments"
                      margin="normal"
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      variant={isDisable ? "filled" : "standard"}
                    />
                  )}
                /> */}

          </div>
          {
            showFields ?
              <div className='col-lg-6'>
                <Autocomplete
                  value={values.supplier ? suppliersValues.find(v => v.id === values.supplier) || {} : null}
                  name={'supplier'}
                  loading={values.supplier}
                  id={'supplier'}
                  // defaultValue={suppliersValues.find(v => v.id === )}
                  getOptionLabel={(option) => option && (option.supplier_name + " - " + option.supplier_org_no)}
                  options={suppliersValues}
                  onChange={(e, option) => {
                    if (option) {
                      setValues({ ...values, supplier: option.id })
                    }
                  }}
                  disabled={isDisable ? isDisable : false}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={!values.supplier ? true : false}
                      label="Supplier"
                      margin="normal"
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      variant={isDisable ? "filled" : "standard"}
                    />
                  )}
                />
              </div> : null
          }

          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}
              required
              error={!values.agrement_info_no ? true : false}
              label="Agreement info no"
              id="Agreement info no"
              //error={errValues && errValues.agrement_info_noError ? true : false}
              className={classes.textField}
              name={'agrement_info_no'}
              value={values.agrement_info_no}
              onChange={handleChange('agrement_info_no')}
              margin="normal"
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
            />
          </div>
          {
            showFields ?
              <div className='col-lg-6'>
                <Autocomplete
                  value={values.bank ? bankLists.find(v => v.id === values.bank) || {} : null}
                  name={'bank'}
                  loading={values.bank}
                  id={'bank'}
                  // defaultValue={suppliersValues.find(v => v.id === )}
                  getOptionLabel={(option) => option && (option.bank_name + " - " + option.bank_org_no)}
                  options={bankLists}
                  onChange={(e, option) => {
                    if (option) {
                      setValues({ ...values, bank: option.id })
                    }
                  }}
                  disabled={isDisable ? isDisable : false}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!values.bank ? true : false}
                      label="Bank"
                      margin="normal"
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      variant={isDisable ? "filled" : "standard"}
                    />
                  )}
                />
              </div> : null
          }
          {false && <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}
              id="our_agreement_ref"
              label="Our AG Ref"
              name={'our_agreement_ref'}
              className={classes.textField}
              value={values.our_agreement_ref}
              onChange={handleChange('our_agreement_ref')}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>}
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}
              required
              id="type_of_leasing"
              select
              error={!values.type_of_leasing ? true : false}
              className={classes.textField}
              value={values.type_of_leasing}
              //error={errValues && errValues.type_of_leasingError ? true : false}
              name="type_of_leasing"
              onChange={handleChange('type_of_leasing')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              label="Type of Lease"
              margin="normal"
            >
              <MenuItem key={'rent'} value="Rent">Rent</MenuItem>
              <MenuItem key={'finance'} value="Financiel Leasing">Financiel Leasing</MenuItem>
              <MenuItem key={'operational'} value="Operational Leasing">Operational Leasing</MenuItem>
            </TextField>
          </div>
          <div className='col-lg-6'>
            <TextField
              variant={isDisable ? "filled" : "standard"}
              required
              id="date"
              InputProps={{
                disabled: isDisable
              }, { inputProps: { min: "1999-05-01", max: "2070-05-04" } }}
              label="Lease Start Date"
              type={isDisable ? "text" : "date"}
              error={!values.lease_start_date ? true : false}
              name={'lease_start_date'}
              margin="normal"
              onChange={handleChange('lease_start_date')}
              //error={errValues && errValues.lease_start_dateError ? true : false}
              value={values.lease_start_date}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          {
            !checkPartner() &&
            <div className='col-lg-6'>
              <Autocomplete
                value={values.partner ? (partnersValues.length > 0 ? partnersValues.find(v => v.id === values.partner) : []) || {} : null}
                name={'partner'}
                loading={values.partner}
                id={'partner'}
                disabled={checkPartner()}
                getOptionLabel={(option) => option && (option.partner_name + " - " + option.partner_org_no)}
                options={partnersValues}
                //error={errValues && errValues.partnerError ? true : false}
                onChange={(e, option) => {
                  if (option) {
                    setPartnerUsersList([]);
                    setValues({ ...values, partner: option.id, clients_attn: [] })
                  }
                }}
                disabled={isDisable ? isDisable : false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    error={!values.partner ? true : false}
                    label="Partner"
                    margin="normal"
                    variant={isDisable ? "filled" : "standard"}

                  />
                )}
              />
            </div>
          }
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}

              required
              label="No of Month Lease"
              id="No of Month Lease"
              className={classes.textField}
              error={!values.no_of_month_lease ? true : false}
              name={'no_of_month_lease'}
              value={values.no_of_month_lease}
              onChange={handleChange('no_of_month_lease')}
              //error={errValues && errValues.no_of_month_leaseError ? true : false}
              margin="normal"
            />
          </div>
          {
            !checkClient() &&
            <div className='col-lg-6'>
              <Autocomplete
                value={values.client ? clientsValues.find(v => v.id === values.client) : null}
                name={'client'}
                loading={values.client}
                id={'client'}
                //error={errValues && errValues.clientError ? true : false}
                getOptionLabel={(option) => option && (option.name + " - " + option.clients_org_no)}
                options={clientsValues}
                // disabled={checkClient()}
                onChange={(e, option) => {
                  if (option) {
                    setValues({ ...values, client: option.id, clients_attn: [] })
                    // getClientUsersList(option.id)
                  }
                }}
                disabled={checkClient() || (isDisable || checkClient() ? isDisable : false)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    error={!values.client ? true : false}
                    label="Client"
                    margin="normal"
                    variant={isDisable ? "filled" : "standard"}

                  />
                )}
              />
            </div>
          }
          {
            showFields ?

              <div className='col-lg-6'>
                <TextField
                  date
                  label="Lease end"
                  id="EOL Date"
                  name={'eol_date'}
                  className={classes.textField}
                  value={values.eol_date}
                  margin="normal"
                  InputProps={{
                    disabled: true
                  }}
                  variant="filled"
                  InputLabelProps={{
                    shrink: true,
                    disabled: true
                  }}
                />
              </div> : null
          }
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}
              required
              label="Billing period"
              id="Billing period"
              error={!values.billing_period ? true : false}
              name={'billing_period'}
              select
              className={classes.textField}
              value={values.billing_period}
              onChange={handleChange('billing_period')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
            >
              <MenuItem key={'0'} value={'1'}>1</MenuItem>
              <MenuItem key={'1'} value={'3'}>3</MenuItem>
              <MenuItem key={'2'} value={'6'}>6</MenuItem>
              <MenuItem key={'3'} value={'12'}>12</MenuItem>
            </TextField>
          </div>
          {
            showFields ?
              <div className='col-lg-6'>
                <TextField
                  InputProps={{
                    disabled: isDisable
                  }}
                  variant={isDisable ? "filled" : "standard"}

                  id="Credit limit"
                  label="Credit limit"
                  className={classes.textField}
                  name={'sum_purchase_value_budget'}
                  value={(values.sum_purchase_value_budget)}
                  onChange={handleChange('sum_purchase_value_budget')}
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        SEK
                      </InputAdornment>
                    )
                  }}
                />
              </div> : null
          }
          <div className='col-lg-6'>
            <TextField
              label="Sum assets value"
              id="Sum asset Value"
              className={classes.textField}
              name={'sum_purchase_value'}
              value={values.sum_purchase_value}
              // onChange={handleChange('sum_purchase_value')}
              margin="normal"
              variant="filled"
              InputProps={{
                disabled: true
              }}
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              label="Sum purchase value"
              id="Sum Purchase Value"
              className={classes.textField}
              name={'sum_purchase_value_1'}
              value={values.sum_purchase_value_1}
              onChange={handleChange('sum_purchase_value_1')}
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
              variant={isDisable ? "filled" : "standard"}
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}

              required
              error={!values.invoicing_to ? true : false}
              label="Invoicing to"
              id="invoicing_to"
              name={'invoicing_to'}
              select
              error={!values.invoicing_to ? true : false}
              className={classes.textField}
              value={values.invoicing_to}
              onChange={handleChange('invoicing_to')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
            >
              <MenuItem key={'main'} value={'client'}>Client</MenuItem>
              <MenuItem key={'cc'} value={'cc'}>CC</MenuItem>
              <MenuItem key={'partner'} value={'partner'}>Partner</MenuItem>
            </TextField>
          </div>
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}

              required
              id="ag status"
              select
              error={!values.ag_status ? true : false}
              className={classes.textField}
              value={values.ag_status}
              name={'ag_status'}
              onChange={handleChange('ag_status')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              label="Status"
              margin="normal"
            >
              {AG_STATUS.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </div>
          {
            showFields ?

              <div className='col-lg-6'>
                <TextField
                  label="Remaining of the credit"
                  name={'remaining_credit'}
                  id={'remaining_credit'}
                  className={classes.textField}
                  value={values.remaining_credit}
                  onChange={handleChange('remaining_credit')}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    disabled: true
                  }}
                  variant="filled"
                />
              </div> : null
          }
          <div className='col-lg-6'>
            <TextField
              variant={isDisable ? "filled" : "standard"}
              id="date"
              InputProps={{
                disabled: isDisable
              }, { inputProps: { min: "1999-05-01", max: "2070-05-04" } }}
              label="Interim start date"
              type={isDisable ? "text" : "date"}
              error={!values.lease_start_date ? true : false}
              name={'original_sign_date'}
              margin="normal"
              onChange={handleChange('original_sign_date')}
              //error={errValues && errValues.lease_start_dateError ? true : false}
              value={values.original_sign_date}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}

            // InputProps={{
            //   disabled: isDisable
            // }}
            // variant={isDisable ? "filled" : "standard"}
            // id="date"

            // label="Interim start date"
            // id="Interim start date"
            // name={'original_sign_date'}
            // className={classes.textField}
            // value={values.original_sign_date}
            // // InputProps={{ inputProps: { min: "1999-05-01", max: "2070-05-04" } }}
            // margin="normal"
            // format="YYYY-MM-DD"
            // onChange={handleChange('original_sign_date')}
            // // formatDate={() => moment().format('YYYY-MM-DD')}
            // // defaultValue="2017-05-24"
            // className={classes.textField}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            />
          </div>
          {
            showFields ?
              <div className='col-lg-6'>
                <TextField
                  InputProps={{
                    disabled: isDisable
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant={isDisable ? "filled" : "standard"}
                  label="Invoiced to bank"
                  id="invoiced_to_bank"
                  name="invoiced_to_bank"
                  className={classes.textField}
                  value={values.invoiced_to_bank}
                  onChange={handleChange('invoiced_to_bank')}
                  // error={foundingError ? true : false}
                  // helperText={foundingError}
                  // margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        SEK
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              : null
          }
          {/* <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}

              label="Type of Assets"
              name={'type_of_product'}
              id={'type_of_product'}
              select
              className={classes.textField}
              value={values.type_of_product}
              onChange={handleChange('type_of_product')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              label="Asset Types"
              margin="normal"
            >
              {assetTypesValues.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </div> */}
          {
            showFields ?
              <div className='col-lg-6'>
                <TextField
                  InputProps={{
                    disabled: isDisable
                  }}
                  variant={isDisable ? "filled" : "standard"}

                  label="Client interest"
                  id="Client interest"
                  name="client_interest"
                  className={classes.textField}
                  value={values.client_interest}
                  onChange={handleChange('client_interest')}
                  error={clientInterestError ? true : false}
                  helperText={clientInterestError}
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <i className="fas fa-percent" />
                      </InputAdornment>
                    )
                  }}
                />
              </div> : null
          }
          <div className='col-lg-6'>
            <Autocomplete
              value={values.clients_attn ? clientUsersList.find(v => v.id === values.clients_attn) : null}
              name={'clients_attn'}
              loading={values.clients_attn}
              id={'clients_attn'}
              getOptionLabel={(option) => option && (option.email)}
              options={clientUsersList}
              disabled={checkClient()}
              onChange={(e, option) => {
                if (option) {
                  setValues({ ...values, clients_attn: option.id })
                }
              }}
              disabled={isDisable ? isDisable : false}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  error={!values.clients_attn ? true : false}
                  label="Client Attn"
                  margin="normal"
                  variant={isDisable ? "filled" : "standard"}

                />
              )}
            />
          </div>
          {
            !hideColumns && <div className='col-lg-6'>
              <TextField
                variant={"filled"}
                label="Tariff"
                id="Tariff"
                name={'montly_payment_ratio'}
                className={classes.textField}
                value={SVPercentageformatter(values.montly_payment_ratio)}
                onChange={handleChange('montly_payment_ratio')}
                error={tariffError ? true : false}
                helperText={tariffError}
                margin="normal"
                InputProps={{
                  disabled: true,
                  pattern: '[0-9,.]',
                  endAdornment: (
                    <InputAdornment position="end">
                      <i className="fas fa-percent" />
                    </InputAdornment>
                  )
                }}
              />
            </div>
          }

          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}

              label="Project"
              id="Project"
              name={'project'}
              className={classes.textField}
              value={values.project}
              onChange={handleChange('project')}
              margin="normal"
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              // InputProps={{
              //   disabled: isDisable
              // }}
              variant={isDisable ? "filled" : "standard"}

              label="Montly payment rent or lease"
              id="Montly payment rent or lease"
              name={'montly_payment_rent_or_lease'}
              className={classes.textField}
              value={values.montly_payment_rent_or_lease}
              onChange={handleChange('montly_payment_rent_or_lease')}
              margin="normal"
            // InputProps={{
            //   disabled: true
            // }}
            />
          </div>
          <div className='col-lg-6'>
            <Autocomplete
              value={values.salesperson ? salespersonUsersList.find(v => v.email === values.salesperson) : null}
              name={'salesperson'}
              loading={values.salesperson}
              id={'salesperson'}
              getOptionLabel={(option) => option && (option.email)}
              options={salespersonUsersList}
              onChange={(e, option) => {
                if (option) {
                  setValues({ ...values, salesperson: option.email })
                }
              }}
              disabled={isDisable ? isDisable : false}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  error={!values.salesperson ? true : false}
                  label="Intern sales person"
                  margin="normal"
                  variant={isDisable ? "filled" : "standard"}
                />
              )}
            />
          </div>
          <div className='col-lg-6'>
            <Autocomplete
              value={supplierUsersList?.length > 0 && values.suppliersalesperson ? supplierUsersList.find(v => v.email === values.suppliersalesperson) : null}
              name={'suppliersalesperson'}
              loading={values.suppliersalesperson}
              id={'suppliersalesperson'}
              getOptionLabel={(option) => option && (option.email)}
              options={supplierUsersList}
              onChange={(e, option) => {
                if (option) {
                  setValues({ ...values, suppliersalesperson: option.email })
                }
              }}
              disabled={isDisable ? isDisable : false}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  error={!values.suppliersalesperson ? true : false}
                  label="Supplier sales person"
                  margin="normal"
                  variant={isDisable ? "filled" : "standard"}
                />
              )}
            />
          </div>
          <div className='col-lg-6'>
            <Autocomplete
              value={values.partnersalesperson ? partnerUsersList.find(v => v.email === values.partnersalesperson) : null}
              name={'partnersalesperson'}
              loading={values.partnersalesperson}
              id={'partnersalesperson'}
              getOptionLabel={(option) => option && (option.email)}
              options={partnerUsersList}
              onChange={(e, option) => {
                if (option) {
                  setValues({ ...values, partnersalesperson: option.email })
                }
              }}
              disabled={isDisable ? isDisable : false}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  error={!values.partnersalesperson ? true : false}
                  label="Partner sales person"
                  margin="normal"
                  variant={isDisable ? "filled" : "standard"}
                />
              )}
            />
          </div>
          {
            (isAdmin || isPartner) ?
              <>
                <div className='col-lg-6'>
                  <Autocomplete
                    multiple
                    limitTags={2}
                    id="tags-standard"
                    options={clientUsersList && clientUsersList?.length > 0 ? clientUsersList.map((item) => item.email) : []}
                    getOptionLabel={(option) => option}
                    // value={values.EOL_notify_info ? clientUsersList.find(v => v.id === values.EOL_notify_info) : null}
                    // defaultValue={values && values.EOL_notify_info}
                    name={'EOL_notify_info'}
                    loading={values.EOL_notify_info}
                    disabled={checkClient()}
                    onChange={(e, option) => {
                      if (option) {
                        setValues({ ...values, EOL_notify_info: option })
                      }
                    }}
                    disabled={isDisable ? isDisable : false}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="EOL info notify"
                        placeholder="Users"
                      />
                    )}
                  />

                  <span style={{ letterSpacing: '.4px' }}>{values && values.EOL_notify_info?.toString()} </span>
                </div>
                <div className='col-lg-6'>
                  <Autocomplete
                    value={values.notice_before_eol ? months.find(val => val.value === values.notice_before_eol) : null}
                    name={'notice_before_eol'}
                    loading={values.notice_before_eol}
                    id={'notice_before_eol'}
                    getOptionLabel={(option) => option.label}
                    options={months}
                    onChange={(e, option) => {
                      if (option) {
                        setValues({ ...values, notice_before_eol: option.value })
                      }
                    }}
                    disabled={isDisable ? isDisable : false}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        error={!values.notice_before_eol ? true : false}
                        label="Notice before EOL"
                        margin="normal"
                        variant={isDisable ? "filled" : "standard"}
                      />
                    )}
                  />


                </div>

                <div className='col-lg-6'>
                  <Checkbox
                    // defaultChecked={values.enable_EOL_choice}
                    checked={values.enable_EOL_choice}
                    onChange={(event) => {
                      setValues({
                        ...values,
                        'enable_EOL_choice': event.target.checked,
                      });
                    }}
                  // checked={}
                  /> Enable EOL choices

                  <Checkbox
                    checked={values.EOL_no_reminder ? values.EOL_no_reminder : false}
                    onChange={(event) => {
                      setValues({
                        ...values,
                        'EOL_no_reminder': event.target.checked,
                      });
                    }}
                  // checked={}
                  /> Disable reminder
                </div>
                <div className='col-lg-6'>
                  <TextField
                    id="EOL_status"
                    select
                    InputProps={{
                      disabled: isDisable
                    }}
                    variant={isDisable ? "filled" : "standard"}
                    className={classes.textField}
                    value={values.EOL_status}
                    name="EOL_status"
                    onChange={handleChange('EOL_status')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    label="Please select EOL status"
                    margin="normal"
                  >
                    <MenuItem key={'none'} value={''}>
                      {'Please choose'}
                    </MenuItem>
                    {EOL_STATUS.map(option => (
                      <MenuItem key={option.label} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </> : null
          }
        </div>
        {/* </form> */}
      </div>
    </div>
  )
}
