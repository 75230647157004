import React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import { checkIsActive, KTSVG } from '../../../helpers'
import { useLayout } from '../../core'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  clickable?: boolean
}

const AsideMenuItem: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  clickable,
  hasBullet = false,
}) => {
  const { pathname } = useLocation()
  const isActive = checkIsActive(pathname, to)
  const { config } = useLayout()
  const { aside } = config

  return (
    <div className='menu-item'>
      <div className="menu-item-flex">

        {icon && !clickable && (
          <div className="without-click">
            <span className='menu-icon'>
              <img src={icon} className='png-icon' />
            </span>
          </div>
        )}
        {to &&
          <Link className={clsx('menu-link without-sub', { active: isActive })} to={to}>
            {icon && clickable && (
              <span className='menu-icon'>
                <img src={icon} className='png-icon' />
              </span>
            )}
            {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
            <span className='menu-title'>{title}</span>
          </Link>
        }
      </div >
      { children}
    </div >
  )
}

export { AsideMenuItem }
