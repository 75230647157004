import axios from 'axios'
import { AuthModel } from '../models/AuthModel'
import { UserModel } from '../models/UserModel'
import { API_URL } from '../../../../_metronic/helpers/components/constant'
// const API_URL = "https://assetapi.inspirepro.co.in"

export const GET_USER_BY_accessToken_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const AUTH_LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/register`
export const UPDATEUSER_URL = `${API_URL}/users`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/password/request`
export const RESET_PASSWORD_URL = `${API_URL}/auth/password/reset`

export const CURRENT_USER = `${API_URL}/users/me?fields[]=id,userType,country,tfa_secret,isNewUser,city,address,phone_number,email,first_name,last_name,role.name,role.id,role.description,client,partner.partner_name,partner.company_org_no,partner.id,client.id,client.name,client.clients_org_no`
export const GENERATE_TFA = `${API_URL}/users/me/tfa/generate`
export const DISABLE_TFA = `${API_URL}/users/me/tfa/disable`
export const ENABLE_TFA = `${API_URL}/users/me/tfa/enable`
export const CHECKUSER = `${API_URL}/assetitems/checkUser`
export const CLEARRSERET = `${API_URL}/assetitems/tfadisable`

// Server should return AuthModel
export function login(email: string, password: string, otp: string) {

  return axios.post(AUTH_LOGIN_URL, {
    email: email,
    password: password,
    mode: 'cookie',
    otp: otp
  })
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string) {
  return axios.post<AuthModel>(REGISTER_URL, {
    email,
    firstname,
    lastname,
    password,
  })
}


// Server should return AuthModel
export function updateuser(id: string, param: any) {
  return axios.patch<AuthModel>(`${UPDATEUSER_URL}/${id}`, param)
}
export function resetPassword(password: string, token: string) {
  return axios.post<AuthModel>(RESET_PASSWORD_URL, {
    "token": token,
    "password": password
  })
}

export function requestPassword(email: string) {
  return axios.post<AuthModel>(REQUEST_PASSWORD_URL, {
    email
  })
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<UserModel>(CURRENT_USER)
  // return axios.get<UserModel>(GET_USER_BY_accessToken_URL)
}

export function generate2FA(password: string, token: string) {
  return axios.post(GENERATE_TFA, { password: password }, { headers: { 'Authorization': `Bearer ${token}` } })
}

export function enableTfa(secret: string, otp: string, token: string) {
  return axios.post(ENABLE_TFA, { secret: secret, otp: otp }, { headers: { 'Authorization': `Bearer ${token}` } })
}
export function checkUser(email: string) {
  return axios.get<{ result: boolean }>(CHECKUSER, {
    params: {
      email: email,
    },
  })
}

export function cleartfaseret(id: string) {
  return axios.patch(`${CLEARRSERET}`, { tfa_secret: null, id: id })
}
