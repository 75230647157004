import axios from 'axios'
import { directUSAPIToken } from '../../../../_metronic/helpers/components/utils';
import { API_URL } from '../../../../_metronic/helpers/components/constant'

export const ASSETSSERVICE = `${API_URL}/assetitems`
export const ASSETS = `${API_URL}/items/Asset`
export const PRESETS = `${API_URL}/presets`
export const INVENTORY = `${API_URL}/items/purchased_assets`
const headers = {
  'Authorization': `Bearer ${directUSAPIToken()}`
}
// Server should return AuthModel
export function getassetsList(queryParam) {
  return axios.get(`${ASSETS}${queryParam}`, {
    params: {},
    headers
  })
}

export function getagreementassetsList(queryParam) {
  return axios.get(`${ASSETSSERVICE}/agreementbulkupdate${queryParam}`, {
    params: {},
    headers
  })
}

export function getDashboardAssets(queryParam, searchValue = null) {
  let param = {
    agids: [queryParam],
    searchValue: searchValue
  }
  return axios.post(`${ASSETSSERVICE}/asset_dashboardNew`, param, { headers })
}

export function updateassetsList(queryParam) {
  return axios.get(`${ASSETSSERVICE}/assetbulkupdate${queryParam}`, {
    params: {},
    headers
  })
}

export function CreateAsset(param) {
  delete param.id
  return axios.post(ASSETS, param, { headers })
}

export function UpdateAsset(param, id) {
  // console.log("param ", param)
  delete param.id
  return axios.patch(`${ASSETS}/${id}`, param, { headers })
}

export function CreateInventory(param) {
  delete param.id
  return axios.post(INVENTORY, param, { headers })
}

export function UpdateInventory(param, id) {
  // console.log("param ", param)
  delete param.id
  return axios.patch(`${INVENTORY}/${id}`, param, { headers })
}

export function UpdateFilter(param, id) {
  // console.log("param ", param)
  return axios.patch(`${PRESETS}/${id}`, param, { headers })
}
export function CreateFilter(param) {
  // console.log("param ", param)
  return axios.post(`${PRESETS}`, param, { headers })
}
export function getFilters(queryParam) {
  return axios.get(`${PRESETS}${queryParam}`, {
    params: {},
    headers
  })
}

export function assetDetail(queryParam) {
  return axios.get(`${ASSETS}${queryParam}`, {
    params: {},
    headers
  })
}

export function UpdatePresets(param, id) {
  // console.log("param ", param)
  return axios.patch(`${ASSETSSERVICE}/presets?id=${id}`, param, { headers })
}
export function CreatePresets(param) {
  // console.log("param ", param)
  return axios.post(`${ASSETSSERVICE}/presets`, param, { headers })
}
