import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { SVformatter } from './utils';
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

const TAX_RATE = 0.07;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  card: {
    minWidth: 275,
  },
  table: {
    minWidth: 400,
  },
}));

function ccyFormat(num) {
  return `${num ? SVformatter(num.toFixed()) : 0}`;
}

function priceRow(qty, unit) {
  return qty * unit;
}

function createRow(desc, price) {
  return { desc, price };
}


export default function SpanningTable({
  totalAssetsList
}) {
  const [productSubtotal, setproductSubtotal] = useState(0)

  const classes = useStyles();
  useEffect(() => {
    if (totalAssetsList) {
      let subtotal = totalAssetsList.reduce(function (prev, current) {
        return prev + +current.finance_volume
      }, 0);
      setproductSubtotal(subtotal)
    }
  }, [totalAssetsList])
  // function subtotal(items) {
  //   console.log("itemmm", items)
  //   return items.map(({ value }) => value).reduce((sum, i) => sum + i, 0);
  // }

  return (
    <div className={`card card-custom gutter-b ${classes.card}`}>
      <div className='dasboard-overflow-auto'>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="text-bold" style={{ width: "180px" }}>Product Group</TableCell>
              <TableCell className="text-bold" align="right">Financing Volume</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {totalAssetsList && totalAssetsList.map(row => (
              <TableRow key={row.products}>
                <TableCell>{row.products}</TableCell>
                <TableCell align="right">{ccyFormat(row.finance_volume)}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={1}><span className="font-weight-bolder">Total Amount</span></TableCell>
              <TableCell className="font-weight-bolder" align="right">{ccyFormat(productSubtotal)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </div>
  );
}