import React, { useState, forwardRef, useImperativeHandle } from "react";
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '60%'
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      fontSize: '20px'
    },
    dense: {
      marginTop: theme.spacing(2),
    },
    menu: {
      width: 200,
    },
  }));
export default forwardRef((props, ref) => {
    const classes = useStyles();

    const [selectedDate, setSelectedDate] = useState(null);

    function handleDateChange(d) {

        let date = moment(d.target.value).format('YYYY-MM-DD');
        props.data.date_of_delievery_approval = date;
        // props.colDef.onDateChanged(props);
        setSelectedDate(date);
    }

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                let dateString = null;
                // if (selectedDate) {
                //     dateString = moment(selectedDate).format('YYYY-MM-DD');
                // }
                return selectedDate;
            },
            isCancelAfterEnd: () => {
                return !selectedDate;
            },
            afterGuiAttached: () => {
                if (!props.value) {
                    return;
                }

                // const [_, day, month, year] = props.value.match(/(\d{2})\/(\d{2})\/(\d{4})/);
                // let selectedDate = new Date(year, month - 1, day);
                let date = moment(props.value).format('YYYY-MM-DD');
                setSelectedDate(date);

                // setSelectedDate(selectedDate);
            }
        };
    });
    // console.log("selectedDate", selectedDate)

    return (
        <div className='col-lg-6'>
            <TextField
              id="date"
              type="date"
              label={false}
              name={'date_of_delievery_approval'}
              margin="normal"
              onChange={handleDateChange}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        // <DatePicker
        //     style={{ width: '100%', margin: 0, padding: '6px 10px' }}
        //     margin="normal"
        //     id="date-picker-dialog"
        //     format="YYYY-MM-DD"
        //     defaultValue={props.value ? moment(props.value) : null}
        //     onChange={handleDateChange}
        //     variant="inline"
        //     disableToolbar
        //     placeholder={'Enter ' + props.colDef.headerName}
        // />
    )
});
