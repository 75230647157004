/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {Languages} from './Languages'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import {useDispatch} from 'react-redux'
import {toAbsoluteUrl} from '../../../helpers'
import {Redirect, Switch} from 'react-router-dom'
import SVG from 'react-inlinesvg';

const HeaderUserMenu: FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const dispatch = useDispatch()
  const logout = () => {
    localStorage.removeItem("globalsearchField")
    dispatch(auth.actions.logout())
    localStorage.removeItem("auth_token")
    {
      (localStorage.getItem("userType") === 'PARTNER' || 
      localStorage.getItem("userType") === 'CLIENT' ) ? <Redirect to='/login' /> : <Redirect to='/auth/login' />
    }
  }
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 bo-menu'
      data-kt-menu='true'
    >

      {/* <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'#'} className='menu-link px-5'>
          My Account
        </Link>
      </div> */}

      <div className='menu-item'>
        <a onClick={logout} className='menu-link'>
          <SVG src={toAbsoluteUrl('/media/logos/logout.svg')} />
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
