/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getDatesFromDateRange, SVPercentageformatter, SVRemovePercentageformatter } from '../../../../_metronic/helpers/components/utils'
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";
import TextField from '@material-ui/core/TextField';
import UpcomingRentsList from "./notesList";

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '60%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: '20px'
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}));

export default function Step3({
  values,
  setValues,
  isDisable,
  hideColumns,
  showFields
}) {
  const classes = useStyles();
  const [priceError, setPriceError] = useState('')
  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  useEffect(() => {
    if (values.aggrement && values.date_of_delievery_approval) {
      // values.aggrement.lease_start_date - 
      var lease_start_date = moment(values.aggrement.lease_start_date, "YYYY-MM-DD");
      var date_of_delievery_approval = moment(values.date_of_delievery_approval).startOf('day');
      //Difference in number of days
      if ((lease_start_date).isAfter(date_of_delievery_approval)) {
        values.Interim_no_of_days = Math.round(moment.duration(lease_start_date.diff(date_of_delievery_approval)).asDays())
        if (isNaN(values.Interim_no_of_days)) {
          values.Interim_no_of_days = null
        }
        setValues({ ...values })
      } else {
        values.Interim_no_of_days = values.Interim_no_of_days || null

        setValues({ ...values })

      }
      // form.setFieldsValue({
      //   Interim_no_of_days: moment.duration(given.diff(current)).asDays()
      // })
    }
  }, [values.aggrement, values.date_of_delievery_approval])

  useEffect(() => {
    if (values.priceper_unit && values.no_of_quantity) {
      let priceTot = parseInt(values.no_of_quantity) * parseFloat(SVRemovePercentageformatter(values.priceper_unit))
      values.price_total = (priceTot).toFixed(2)
      setValues({ ...values })
    } else {
      values.price_total = values.price_total || 0
      setValues({ ...values })
    }
  }, [values.no_of_quantity, values.priceper_unit]);

  useEffect(() => {
    if (values.aggrement && values.price_total && values.aggrement?.montly_payment_ratio) {
      // console.log("values.price_total", values.price_total)
      let price_total = values.price_total ? SVRemovePercentageformatter(values.price_total) : 0;
      let rent_period = (((parseFloat(price_total) * parseFloat(values.aggrement.montly_payment_ratio)) / 100) * (values.aggrement.billing_period));
      // console.log(values.aggrement?.billing_period, "agrememnt", values.aggrement?.montly_payment_ratio)
      // console.log("rent period", rent_period)
      let dailrent = (((parseFloat(price_total) * parseFloat(values.aggrement.montly_payment_ratio)) / 100) / 30);
      values.rent_period = (rent_period).toFixed(2)
      values.dailyrent = (dailrent).toFixed(2)
      setValues({ ...values })

    } else {

      values.rent_period = values.rent_period || 0
      values.dailyrent = values.dailyrent || 0
      setValues({ ...values })

    }
  }, [values.aggrement, values.price_total]);

  useEffect(() => {
    if (values?.rent_period && values.aggrement?.lease_start_date) {
      let startdate = values.aggrement.lease_start_date;
      let enddate = values.aggrement.eol_date;
      const dates = getDatesFromDateRange(values.aggrement.billing_period, SVRemovePercentageformatter(values.rent_period), startdate, enddate, "YYYY/MM/DD", 1);
      // console.log("datesss", dates)
      values.upcomingrents = dates;
      setValues({ ...values })
    }
  }, [values.aggrement, values.rent_period])

  useEffect(() => {
    if (values.Interim_no_of_days && values.dailyrent) {
      let Interim_rent_cost = (values.Interim_no_of_days * SVRemovePercentageformatter(values.dailyrent))
      values.Interim_rent_cost = (Interim_rent_cost).toFixed(2)
    } else {
      values.Interim_rent_cost = values.Interim_rent_cost || 0
    }
    setValues({ ...values })

  }, [values.Interim_no_of_days, values.dailyrent])

  return (
    <div className="card card-custom gutter-b">
      <div className='card-body box-shadow'>
        <div className="form-group row">
          <div className='col-lg-6'>
            <TextField
              type={isDisable ? "text" : "date"}
              margin="normal"
              format="YYYY-MM-DD"
              label="Date of delivery approval"
              name="date_of_delievery_approval"
              id="date_of_delievery_approval"
              className={classes.textField}
              InputProps={{
                disabled: isDisable
              }, { inputProps: { min: "1999-05-01", max: "2070-05-04" } }}
              variant={isDisable ? "filled" : "standard"}
              value={(values.date_of_delievery_approval)}
              onChange={handleChange('date_of_delievery_approval')}
              // formatDate={() => moment().format('YYYY-MM-DD')}
              // defaultValue="2017-05-24"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}
              label="No of quantity"
              name="no_of_quantity"
              id="no_of_quantity"
              className={classes.textField}
              value={values.no_of_quantity}
              onChange={handleChange('no_of_quantity')}
              margin="normal"
            />
          </div><div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}
              label="Price per units"
              id="Price per units"
              name="priceper_unit"
              error={priceError ? true : false}
              helperText={priceError}
              className={classes.textField}
              value={values.priceper_unit ? SVPercentageformatter(values.priceper_unit) : ''}
              onChange={handleChange('priceper_unit')}
              margin="normal"
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              name="price_total"
              id="price_total"
              label="Price total"
              className={classes.textField}
              value={values.price_total ? SVPercentageformatter(values.price_total) : ''}
              InputProps={{
                disabled: true
              }}
              variant="filled"
              margin="normal"
            />
          </div><div className='col-lg-6'>
            <TextField
              id="rent_period"
              name="rent_period"
              label="Rent / Period"
              className={classes.textField}
              value={values.rent_period ? SVPercentageformatter(values.rent_period) : ''}
              InputProps={{
                disabled: true
              }}
              variant="filled"
              margin="normal"
            />
          </div>
          {
            showFields &&
            <div className='col-lg-6'>
              <TextField
                id="dailyrent"
                name="dailyrent"
                label="Daily rent"
                className={classes.textField}
                value={values.dailyrent ? SVPercentageformatter(values.dailyrent) : ''}
                InputProps={{
                  disabled: true
                }}
                variant="filled"
                margin="normal"
              />
            </div>
          }
          {
            (!hideColumns && showFields) && <>
              <div className='col-lg-6'>
                <TextField
                  id="Interim_no_of_days"
                  name="Interim_no_of_days"
                  label={'Interim no of days'}
                  className={classes.textField}
                  value={values.Interim_no_of_days || null}
                  InputProps={{
                    disabled: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="filled"
                  margin="normal"
                />
              </div>
              {
                showFields &&
                <div className='col-lg-6'>
                  <TextField
                    id="Interim_rent_cost"
                    name="Interim_rent_cost"
                    label="Interim rent"
                    className={classes.textField}
                    value={values.Interim_rent_cost ? SVPercentageformatter(values.Interim_rent_cost) : ''}
                    InputProps={{
                      disabled: true
                    }}
                    variant="filled"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                  />
                </div>
              }
              <div className='col-lg-6'>
                <UpcomingRentsList upcomingrents={values.upcomingrents} />
              </div>
            </>
          }

        </div>
      </div>
    </div>
  )
}
