import axios from 'axios'
import { directUSAPIToken } from '../../../../_metronic/helpers/components/utils';
import {API_URL} from '../../../../_metronic/helpers/components/constant'


export const SUPPLIERS = `${API_URL}/items/supplier`
const headers = {
  'Authorization': `Bearer ${directUSAPIToken()}`
}
// Server should return AuthModel
export function getSupplierList(queryParam) {
  // console.log("filedss", fields)
  // let param = "?fields="
  // param = fields ? param+fields: param;
  return axios.get(`${SUPPLIERS}${queryParam}`, {
    params: {},
    headers
  })
}

export function Create(param) {
  // console.log("pararaam", param)
  return axios.post(SUPPLIERS, param, { headers })
}

export function UpdateSupplier(param, id) {
  console.log("param ", param)
  delete param.id
  return axios.patch(`${SUPPLIERS}/${id}`, param, { headers })
}

export function supplierDetail(queryParam) {
  return axios.get(`${SUPPLIERS}${queryParam}`, {
    params: {},
    headers
  })
}