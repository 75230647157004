
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { directUSAPICall } from "../../utils"
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '60%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: '20px'
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  dialogRoot: {
    width: '650px !important',
    height: '500px',
    //   display: 'flex',
    //   flexDirection: 'column',
  },
  menu: {
    width: 200,
  },
}));

export default function AlertDialog({
  showDialog,
  selectedItem,
  handleCancel,
  collection,
  userPermission
}) {
  const [description, setDescription] = useState('')
  const [notes, setNotes] = React.useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState({});
  const [loading, setLoading] = useState(false)

  const classes = useStyles()
  function handleClose() {
    handleCancel(false);
  }

  useEffect(() => {
    if (selectedItem && selectedItem.id) {
      // setAssetData(selectedItem.data)
      setEditData({})
      setNotes("")
      getNotes(selectedItem.id)
    }
  }, [selectedItem])

  const getNotes = async (id) => {
    setLoading(true)
    let options = {
      limit: -1,
      sort: '-id',
      fields: ['description,id,agreement'],
      filter: {
        agreement: {
          _eq: id,
        },
      },
    }
    const result = await directUSAPICall().items('notes').readMany(options);
    if (result.data && result.data.length > 0) {
      setNotes(result.data);
      setLoading(false)
    } else {
      setNotes([])
      setLoading(false)
    }

  }

  const submit = async () => {
    if (description) {
      let result = ""
      let param = {
        description: description,
        collection: collection
      }
      if (collection === 'Asset') {
        param.asset = selectedItem.id
      } else {
        param.agreement = selectedItem.id
      }
      setLoading(true)
      if (editData && editData.id) {
        result = await directUSAPICall().items('notes').updateOne(editData.id, param);
      } else {
        result = await directUSAPICall().items('notes').createOne(param);
      }
      if (result.id) {
        setEditData({})
        setDescription("")
        getNotes(selectedItem.id);
      } else {
        setLoading(false)
      }
    }

  }

  const deleteNotes = async (id) => {
    let result = ""
    setLoading(true)
    if (id) {
      result = await directUSAPICall().items('notes').deleteOne(id);
    }
    getNotes(selectedItem.id);

  }

  const edit = (item) => {
    setEditMode(true)
    setEditData(item)
    setDescription(item.description)
  }

  return (
    <div>
      <Dialog
        open={showDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='lg'
        className='dialogRoot'
        classes={{ paper: classes.dialogRoot }}
      >
        <DialogTitle id="alert-dialog-title text-uppercase">{collection} - ({selectedItem?.id}) {collection === 'Asset' ? ` - Agreement (${selectedItem?.aggrement?.id})` : null}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form
              autoComplete="off"
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
              noValidate
              id='kt_login_signup_form'
            // onSubmit={handleSubmit}
            >
              <div className="cards card-custom gutter-b">
                <div className="form-group row notes-s">
                  <label className='form-label fw-bolder text-dark fs-6'>Notes</label>
                  <div className='col-lg-12'>
                    <TextField
                      id="standard-multiline-flexible"
                      required
                      multiline
                      maxRows="10"
                      rows={6}
                      name={'description'}
                      value={description}
                      onChange={(e) => {
                        const { value } = e.target;
                        setDescription(value)
                      }
                      }
                      className={classes.textField}
                    //  margin="normal"
                    />
                  </div>
                </div>
              </div>
            </form>
            <div className='card-body pt-2'>
              {
                notes?.length > 0 ? notes.map((item, key) => (
                  <div key={key} className='d-flex align-items-center mb-8'>
                    {/* begin::Bullet */}
                    <span className='bullet bullet-vertical h-40px bg-success'></span>
                    {/* end::Bullet */}
                    {/* begin::Checkbox */}
                    <div className='form-check form-check-custom form-check-solid mx-5'>
                    </div>
                    {/* end::Checkbox */}
                    {/* begin::Description */}
                    <div className='flex-grow-1 bigfont'>
                      {item.description}
                    </div>
                    {/* end::Description */}
                    <span className='badge badge-light-success fs-8 fw-bolder'>

                      <i className="fas fa-edit" onClick={() => edit(item)} />
                      <i className="fas fa-trash-alt" onClick={() => deleteNotes(item.id)} />
                    </span>
                  </div>
                )) :
                  <div className='d-flex align-items-center mb-8'>
                    No data found
                          </div>
              }
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            onClick={() => handleClose(false)}
            color='secondary'
            // disabled={loading}
            className={classes.button}
          >
            Cancel
            </Button>
          {
            userPermission.canNotes && <Button
              variant='contained'
              onClick={() => submit()}
              color='primary'
              // disabled={loading || !enableSubmission}
              className={classes.button}
            >
              Create
              {loading && (
                <CircularProgress
                  size='1rem'
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          }

        </DialogActions>
      </Dialog>
    </div>
  );
}
