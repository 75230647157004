import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { MenuItem } from "@material-ui/core";

import { Link, useHistory, useLocation } from 'react-router-dom'
// import FileUpload from './components/FileUpload';
import moment from "moment";
import { AgGridReact } from 'ag-grid-react';
import _ from "lodash";
import Button from '@material-ui/core/Button';

import FileUpload from "../../../../app/modules/assets/components/PurchasedFileUpload"
import { directUSAPICall, SVPercentageformatter, SVformatter, getAgValuesFormat, generateExcel, FormatFilterValues } from "../utils"
import { PURCHASEDASSETSOVERVIEW, PURCHASEDASSETS } from "../constant";
import DeleteConfirmDialog from "../../components/deleteConfirm"
import { useStyles } from "./components/utils";
import { useParams } from 'react-router-dom';
import AssetViewDialog from '../../../../app/modules/assets/components/AssetViewDialog'
// import Pagination from "./components/paging";
export default function AssetForm(props) {
  const {
    setTotalAssets,
    searchString,
    page,
    searchcolumns,
    currentpage
  } = props;
  const classes = useStyles();
  const history = useHistory()
  const urlParams = useParams();
  const overlayLoadingTemplate =
    '<span className="ag-overlay-loading-center">Loading, Please Wait...</span>';
  const noRowsTemplate =
    '<span className="ag-overlay-loading-center">No records found...</span>';
  const [showNotesDialog, setShowNotesDialog] = useState(false);
  const [fileUploadOpen, setFileUploadOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [gridApi, setGridApi] = useState();
  const [quickFilterText, setQuickFilterText] = useState('');
  const [rowDataAPI, setRowDataAPI] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [enableBulkUpdates, setEnableBulkUpdates] = useState(false);
  const [bulkUpdateOpen, setBulkUpdateOpen] = useState(false);
  const [tablecolumns, setTableColumns] = useState([])
  const [selectedAsset, setSelectedAsset] = useState();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState([]);
  const { data } = useSelector(({ auth }) => auth.user);
  const [userInfo, setUserInfo] = useState(data);
  const [showAssetDetail, setShowAssetDetail] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState('');
  const [userPermission, setUserPermission] = useState({
    canEdit: true,
    canDelete: true,
    canView: true,
    canAdd: true,
    canImport: true,
    canBulkDelete: true,
    canImport: true,
    canBulkUpdate: true,
    canNotes: true
  });
  const [selectedColumns, setSelectedColumns] = React.useState([]);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [presetId, setPresetId] = React.useState()
  const [showFields, setShowFields] = useState(false)
  const [showAssetCreate, setShowAssetCreate] = useState(false);
  const [totalRowDatas, setTotalRowDatas] = useState(0)
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(500);
  const [EOLACTION, setEOLACTION] = useState('')
  const [userTypes, setUserTypes] = useState({
    isClient: false,
    isAdmin: false,
    isPartner: false
  });
  const [agreementListTemp, setAgreementListTemp] = useState([])
  const [rowDataTemp, setRowDataTemp] = useState([]);
  const [tempColumnDefs, setTempColumnDefs] = useState([]);
  const [tempColumns, setTempColumns] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [colValues, setColValues] = useState([]);
  const [tempFieldsList, setTempFieldsList] = useState([]);
  const [totalAssetValue, setTotalAssetValue] = useState('');
  const [dataSourceparams, setDataSourceparams] = useState(null);
  const [globalSearchValues, setglobalSearchValues] = useState(null);
  const gridRef = useRef();
  const [showExport, setShowExport] = useState(false);
  const [isOwnOverview, setisOwnOverview] = useState(null)
  const location = useLocation();
  // const [page, setPage] = useState('')



  const lists = async (gridApi, request = dataSourceparams, page) => {
    setLoading(true);
    gridApi && gridApi.showLoadingOverlay();
    let fields = currentpage === 'overview' ? PURCHASEDASSETSOVERVIEW : PURCHASEDASSETS

    setRowData([]);

    let sort = '-pid';

    const currentpage1 = request.endRow / rowsPerPage;
    let options = {
      limit: rowsPerPage,
      sort: sort,
      fields: [fields],
      page: currentpage1,
      meta: ['total_count', 'filter_count'],
      filter: {
        _and: [
          {
            "status": {
              _nnull: true
            }
          }
        ]
      }
    }
    if (userInfo.role) {
      if ((userInfo?.role?.name === "client_admin") || (userInfo?.role?.name === "client_user")) {
        options.filter['_and'].push({
          "client": {
            _eq: userInfo.client.id
          }
        })
      }
    }
    if (urlParams?.suppliers) {
      options.filter['_and'].push({
        "supplier": {
          "supplier_name": {
            _eq: urlParams?.suppliers
          }
        }
      })
    }
    if (urlParams?.equipment) {
      options.filter['_and'].push({
        "equipment": {
          _eq: urlParams?.equipment
        }
      })
    }
    gridApi && gridApi.showLoadingOverlay();

    const result = await directUSAPICall().items('purchased_assets').readMany(options);
    setTotalRowDatas(result.meta.filter_count)

    if (result.data && result.data.length > 0) {
      let response = result.data;
      setLoading(false)
      if (currentpage === 'overview') {
        setTotalAssets(response)

      }
      setRowData(response)
      setRowDataTemp(response)
      let tempAPI = JSON.parse(JSON.stringify(response));
      setRowDataAPI(tempAPI);
      request.successCallback(response, result.meta.filter_count);
      gridApi && gridApi.hideOverlay();


    } else {
      setLoading(false);
      // setPageLoading(false)
      setRowData([])
      gridApi && gridApi.hideOverlay();
    }

  }


  const getColumnDefinitions = async (gridApi, page) => {
    let options = {
      limit: -1,
      sort: 'order_by_id',
      // fields: [fields]
    }
    // if (userInfo?.userType === "SUPERADMIN" || userInfo?.userType === "ADMINUSER") {
    //   options.filter = {
    //     _and:
    //       [
    //         {
    //           "adminview": {
    //             _eq: true
    //           }
    //         }
    //       ]
    //   }
    // } else if (userInfo?.userType == "PARTNER") {
    //   options.filter = {
    //     _and:
    //       [
    //         {
    //           "partnerview": {
    //             _eq: true
    //           }
    //         }
    //       ]
    //   }
    // } else 
    if (currentpage === 'overview') {

      options.filter = {
        _and:
          [
            {
              "dashboardview": {
                _eq: true
              }
            }
          ]
      }
    }
    const results = await directUSAPICall().items('Asset_purchased_Column_Definitions').readMany(options).then((result) => {
      if (result) {
        // setColumnDefs(result.data)
        setTempColumnDefs(result.data)
      }
    }).catch((err) => {
    })
  }

  const deleteConfirm = (values) => {
    setShowDeleteDialog(true)
    let arr = [];
    arr.push(values.pid)
    setDeleteId(arr)
  }

  const handleBulkDelete = async (props) => {
    let selectedRows = gridApi.getSelectedRows();
    let ids = (selectedRows.map((item) => item.pid));
    setDeleteId(ids)
    setShowDeleteDialog(true)
  };

  const ActionRendererComponent = ({ data }) => {
    let props = data
    return (
      props ?
        <div>

          {
            <i onClick={() => { setSelectedAssetId(props.pid); gotoEdit(props, 'edit') }} className="fas fa-edit" />
          }

          {(userPermission && userPermission.canDelete) && <i className="fas fa-trash-alt deleteone" onClick={() => deleteConfirm(props)} />}
        </div> : null
    );
  }

  let TextFilterParams = {
    filterOptions: ['contains'],
    textFormatter: (r) => {
      if (r == null) return null;
      return r
        .toLowerCase()
        .replace(/[àáâãäå]/g, 'a')
        .replace(/æ/g, 'ae')
        .replace(/ç/g, 'c')
        .replace(/[èéêë]/g, 'e')
        .replace(/[ìíîï]/g, 'i')
        .replace(/ñ/g, 'n')
        .replace(/[òóôõö]/g, 'o')
        .replace(/œ/g, 'oe')
        .replace(/[ùúûü]/g, 'u')
        .replace(/[ýÿ]/g, 'y');
    },
    debounceMs: 200,
    maxNumConditions: 1,
  };

  let EqualFilterParams = {
    filterOptions: ['equals'],
    textFormatter: (r) => {
      if (r == null) return null;
      return r
        .toLowerCase()
        .replace(/[àáâãäå]/g, 'a')
        .replace(/æ/g, 'ae')
        .replace(/ç/g, 'c')
        .replace(/[èéêë]/g, 'e')
        .replace(/[ìíîï]/g, 'i')
        .replace(/ñ/g, 'n')
        .replace(/[òóôõö]/g, 'o')
        .replace(/œ/g, 'oe')
        .replace(/[ùúûü]/g, 'u')
        .replace(/[ýÿ]/g, 'y');
    },
    debounceMs: 200,
    maxNumConditions: 1,
  };

  const buildColumnDefinitions = (columnDefs) => {
    return columnDefs && columnDefs.map((columnDef, index) => {
      let columnDefinition = {
        headerName: (index !== 0) ? columnDef.header_name : currentpage === 'overview' ? columnDef.header_name : '',
        cellRenderer: (columnDef.field === 'actions' && currentpage !== 'overview') ? ActionRendererComponent : false,
        // cellRendererParams: {
        //   onRowEditingStopped: (params) => onRowEditingStopped(params),
        // },
        // headerCheckboxSelection: index === 0 ? true : false,
        headerCheckboxSelectionFilteredOnly: currentpage !== 'overview' ? true : false,
        checkboxSelection: (index === 0 && currentpage !== 'overview') ? true : false,
        field: columnDef.field,
        editable: false,
        filter: columnDef.filter,
        sortable: true,
        resizable: false,
        suppressAggFuncInHeader: true,
        enableCellChangeFlash: true,
        filterParams: (columnDef.type === 'currencyColumn' || columnDef.type === 'number' || columnDef.type === 'date') ? EqualFilterParams : TextFilterParams,
        filterType: columnDef.type === 'currencyColumn' ? 'number' : columnDef.type,
        // hide: getMonthDiff(columnDef.field, params),
        // width: index !== 0 ? ((columnDef.field === 'model') ? 400 : (columnDef.field.length <= 9 ? 90 : columnDef.field.length >= 12 ? 150 : columnDef.field.length >= 17 ? 160 : 180)) : 180,
        width: 'auto',
        // cellClass: columnDef.type === 'currencyColumn' ? 'ag-right-aligned-cell' : '',
        valueFormatter: (params) => {
          // console.log("params", params.data.aggrement)
          if (columnDef.type === 'currencyColumn') {
            return params.value ? SVformatter(params.value) + ' kr' : '0 kr';
          }
          if (params.value && columnDef.type === 'date') {
            return moment(params.value).format('YYYY-MM-DD');
          }
          if (columnDef.type === 'percentage') {
            let val = params.value ? Math.round(params.value * 100) / 100 : '';;
            return val ? SVPercentageformatter(val) : '0.00%';
          }
        },
        valueGetter: (params) => {
          if (params.data) {
            if (params?.data?.client) {
              params.data.client_name = params.data.client.name ? params.data.client.name : ''
              params.data.clients_org_no = params.data.client.clients_org_no ? params.data.client.clients_org_no : ''
            }
            if (params.data.supplier) {
              params.data.supplier_name = params.data.supplier.supplier_name
              params.data.supplier_org = params.data.supplier.supplier_org_no
            }
            if (params.data?.user_created) {
              let user = params.data.user_created
              params.data.created_by = user.first_name;
            }
            if (params.data?.user_updated) {
              let user = params.data.user_updated
              params.data.updated_by = user.first_name;
            }
            if (params.data?.unit_price) {
              params.data.price_total = params.data?.quantity * params.data?.unit_price;
            }

            return params.data[columnDef.field];
          }

        }

      };
      // if (columnDef.field === 'agrement_info_no') {
      //   let agremtList = agreementsInfoList && agreementsInfoList.sort((a, b) => (a > b ? -1 : 1));
      //   columnDefinition.cellEditor = 'DialogEditor';
      //   columnDefinition.cellEditorParams = {
      //     values: agremtList,
      //   };
      // }
      if (columnDef.type === 'date') {
        columnDefinition.cellEditor = 'dateEditor';
      }


      // if (columnDef.field === 'totalAssets') {
      //     columnDefinition.cellRenderer = params => {
      //         params.data.totalAssets = getAssetsCount(params.data.id)
      //         return params.data.totalAssets
      //     };

      // }
      return columnDefinition;
    });
  };

  const frameworkComponents = {
    ActionRendererComponent
  };

  const onModelUpdated = (params) => {
    // console.log("onModelUpdated" ,params)
    if (!enableBulkUpdates) {
      gridApi && gridApi.deselectAll()
    }
    // params.api.sizeColumnsToFit();
  };

  const onRowSelected = (params) => {
    setEnableBulkUpdates(gridApi?.getSelectedRows()?.length > 0);
  };
  const onRowDataChanged = (params) => {
    highlightUnsavedRows(params);
  };

  const highlightUnsavedRows = (params) => {
    if (!params || rowDataAPI.length === 0) {
      return;
    }
    let missingRowNodes = params.api.rowModel.rowsToDisplay.filter((row) => {
      if (!row.data.asset_id) {
        return row;
      }
    });

    if (missingRowNodes.length > 0) {
      missingRowNodes.map((node) => {
        if (params.node !== node) {
          node.setSelected(true);
        }
      });
    }
  };

  const onRowEditingStarted = (params) => {
    if (enableBulkUpdates) {
      return
    }
    gridApi.refreshCells({
      rowNodes: [params.node],
      // columns: [params.columnApi.columnController.allDisplayedColumns[0]],
      force: true,
    });
  };
  const handleBulkUpdate = (params) => {
    setBulkUpdateOpen(true);
  };
  const handleNotestOpen = (params) => {
    setShowNotesDialog(true);
    setSelectedAsset(params)
  };

  const exportExcelData = async () => {
    generateExcel(gridApi, `Assets-${moment().format('YYYY-MM-DD')}.xlsx`, 'assets', tablecolumns, null, selectedColumns);
  }

  const onGridReady = (params) => {
    getColumnDefinitions();
    setGridApi(params.api);

  };


  useEffect(() => {
    if (userInfo && gridApi && tempColumnDefs && tempColumnDefs.length > 0) {
      const dataSource = {
        getRows: (params) => {
          const page = params.endRow / rowsPerPage;
          lists(gridApi, params, page)
          setDataSourceparams(params);
        }
      }
      gridApi.setDatasource(dataSource);
    }
  }, [userInfo && gridApi, tempColumnDefs]);

  const clearFilters = () => {
    gridApi.setFilterModel(null);
  }

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 225,
      floatingFilter: true,
    };
  }, []);

  const gotoEdit = (values, type = null) => {
    if (type === 'edit') {
      setSelectedAssetId(values.pid)
      setLoading(true)
      setShowAssetDetail(true)
    }
    //else {
    //   history.push(`/updateasset/${values.data.id}`)
    // }
  }

  return (
    <>
      {currentpage !== 'overview' &&
        <div className="header-top">

          <div className="card-toolbar btns">

            {
              <Link to='#' className='align-self-center' onClick={() => clearFilters(gridApi)} >
                <i className="fas fa-sync"></i> Clear filters
            </Link>
            }
            {/* {
            userPermission.canBulkUpdate &&
            <Link to='#' className='align-self-center' onClick={() => enableBulkUpdates && handleBulkUpdate(gridApi)} >
              <i className="fas fa-list"></i> Bulk Update
            </Link>
          } */}
            {!searchcolumns && (userPermission && userPermission && userPermission.canBulkDelete) &&
              <Link to="#" className='align-self-center' onClick={() => enableBulkUpdates && handleBulkDelete(gridApi)} ><i className="fas fa-trash-alt"></i> {'Bulk delete'}</Link>
            }
            {

              <Link to="#" className='align-self-center' onClick={() => setFileUploadOpen(true)} >
                <i className="fas fa-upload"></i> {'Import file'}
              </Link>
            }

            <Link className='align-self-center' to="#" onClick={() => exportExcelData()}><i className="fas fa-download"></i> Export file</Link>
            {
              <div className="add-agg"><Link onClick={() => setShowAssetDetail(true)} to="#" className='align-self-center'>
                <Button variant="contained" color="primary" className={classes.button}>
                  <i className="fas fa-plus" style={{ color: 'white' }}></i> Create Asset
              </Button>
                {/* <button type="button" className="btn btn-primary">Create Asset</button> */}
              </Link></div>
            }
          </div>

        </div>
      }
      <div className={`card card-custom gutter-b bg-smoke ${currentpage !== 'overview' ? 'padd' : ''}`}>

        {
          currentpage !== 'overview' && totalAssetValue && <div>
            <span>Total Asset value: </span><span>{SVformatter(totalAssetValue)} kr</span>
          </div>
        }
        {
          currentpage !== 'overview' &&
          <div className='card-header border-0 card-header-sm'>
            <div className={`card-title ${page}-title`}>
              <h3 className='card-label text-bold'>
                {'Inventory List'}
              </h3>
            </div>
          </div>
        }
        <div
          className='ag-theme-quartz'
          style={{
            width: '100%',
            height: `${currentpage === 'overview' ? '498px' : '80vh'}`,
            boxShadow: '0 1px 15px 1px rgba(69,65,78,.08)',
          }}
        >

          <AgGridReact
            ref={gridRef}
            // columnDefs={[{'header_name': "FIRST NAME", 'field': 'first_name'}]}
            columnDefs={buildColumnDefinitions(tempColumnDefs)}
            pagination={true}
            rowModelType={'infinite'}
            paginationPageSize={rowsPerPage}
            cacheBlockSize={rowsPerPage}
            onGridReady={onGridReady}
            defaultColDef={defaultColDef}
            onRowSelec1ed={onRowSelected}
            onRowDataChanged={onRowDataChanged}
            onRowEditingStarted={onRowEditingStarted}
            enableCellTextSelection={true}
            overlayLoadingTemplate={overlayLoadingTemplate}
            overlayNoRowsTemplate={noRowsTemplate}
            onRowSelected={onRowSelected}
            // gotoEdit={gotoEdit}
            enableBulkUpdates={enableBulkUpdates}
            searchString={searchString}
            deleteConfirm={deleteConfirm}
            handleNotestOpen={handleNotestOpen}
            applyColumnDefOrder={true}
            suppressRowClickSelection={true}
            alwaysShowVerticalScroll={true}
            quickFilterText={quickFilterText}
            userPermission={userPermission}
            onModelUpdated={onModelUpdated}
            components={frameworkComponents}
            getRowHeight={20}
            cacheOverflowSize={100}
            maxConcurrentDatasourceRequests={-1}
            infiniteInitialRowCount={1}
            maxBlocksInCache={rowsPerPage}
            rowSelection='multiple'

          />
        </div>
        <DeleteConfirmDialog
          title="Inventory"
          content="are you sure you want to delete this Inventory ?"
          open={showDeleteDialog}
          setOpen={setShowDeleteDialog}
          tableName="purchased_assets"
          deleteId={deleteId}
          tempFieldsList={tempFieldsList}
          getNewData={lists}
          parentGridApi={gridApi}
        />


        <FileUpload
          open={fileUploadOpen}
          setOpen={setFileUploadOpen}
          title='Purchased Asset File upload'
          handleCancel={setFileUploadOpen}
          getNewData={lists}
          parentGridApi={gridApi}
          tempFieldsList={tempFieldsList}
          userInfo={userInfo}
        />
        <AssetViewDialog
          open={showAssetDetail}
          setOpen={setShowAssetDetail}
          fieldDisable={true}
          setLoading={setLoading}
          page="view"
          title={`Inventory`}
          assetId={selectedAssetId}
          setSelectedAsset={setSelectedAssetId}
          userPermission={userPermission}
          isInventory={true}
          handleAssetsList={lists}
          setShowAssetCreate={setShowAssetDetail}
        />
      </div>

      {/* <Sidebar
          handleFilter={submitFilter} columns={temptablecolumns} setTempTableColumns={setTempTableColumns} onClick={onClick} onChange={handleChange} selectedColumns={selectedColumns} />
 */}
      {/* </div> */}
    </>
  )
}

