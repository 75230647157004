import _ from "lodash";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { toAbsoluteUrl } from '../../helpers'
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Search from './Search';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

export default function AutoComplete({
  userRole,
  agreementList,
  clientsValues,
  partnersValues,
  selected,
  setSelected,
  values,
  setValues,
  title,
  getClientList,
  redirect,
  userTypes,
  page,
  getValues,
  agreementListTemp,
  totalAssetsList,
  upcomingrents,
  search,
  resultsFound
}) {
  return (

    <div style={{ width: '100%', background: '#f8f8f8', padding: '12px' }}>
      {
        userTypes && !userTypes.isAdmin && title === 'Overview' && <><form onSubmit={search}><div className="searchsection">
          <TextField
            label=""
            className="searchbox"
            name="searchfield"
            onChange={(e, option) => {
              setValues({ ...values, searchfield: e.target.value })
            }}

            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment>
                  <IconButton onClick={search}>
                    <SearchIcon>search</SearchIcon>
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>
        </form>
          <div className="no-results">
            {resultsFound === 'true' && <span>Your search request suucessfully found the records</span>}
            {resultsFound === 'false' && <span style={{ color: 'red' }}>Your search request does not found the records</span>}
          </div>
        </>
      }

      <div className="row filter">
        <div className='col-lg-3'>
          <div className="title-full">
            {/* <Link to={`/${redirect ? redirect : 'overview'}`}>
              <img className="left-icon" src={toAbsoluteUrl('/media/left_icon.png')} alt='' />
            </Link> */}
            <span>
              {title}
            </span>
          </div>

        </div>
        {
          title === 'Overview' &&
          <div className='col-lg-3'>
            <div className="title-full">

              <Link to={`/agrementassets/${selected.toString()}`}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={selected?.length > 0 ? false : true}
                >
                  <span className='indicator-label'>{'Show Assets'}</span>
                </Button>
              </Link>
            </div>

          </div>
        }
        {
          (userTypes && userTypes.isAdmin || (userRole !== 'partner_admin' && userRole !== 'partner_user')) && partnersValues &&
          <div className='col-lg-3'>
            <Autocomplete
              id="size-small-standard-multi"
              size="small"
              getOptionLabel={(option) => option.partner_name + " - " + option.partner_org_no}
              options={partnersValues}
              onChange={(e, option) => {
                let vals = { ...values, partner: option ? option.id : null }
                localStorage.setItem("filterValues", JSON.stringify(vals))
                setValues(vals)
                setSelected([])
                getValues(option ? option.id : '', values.client, agreementListTemp, totalAssetsList, [], upcomingrents)
                if (option && userRole === 'master_admin' || userRole === 'master_user') {
                  getClientList(option.id, null, 'admin')
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // value={values.partner}
                  label="Partner"
                  margin="none"
                  variant="standard"
                />
              )}
            />
          </div>}
        {
          (userTypes && userTypes.isAdmin || (userRole !== 'client_admin' && userRole !== 'client_user')) && clientsValues &&
          <div className='col-lg-3'>
            <Autocomplete
              id="size-small-standard-multi"
              size="small"
              getOptionLabel={(option) => option.name + " - " + option.clients_org_no}
              options={clientsValues}
              onChange={(e, option) => {
                setSelected([])
                let vals = { ...values, client: option ? option.id : null };
                setValues(vals)
                localStorage.setItem("filterValues", JSON.stringify(vals))
                getValues(values.partner, option ? option.id : '', agreementListTemp, totalAssetsList, [], upcomingrents)

              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // value={values.client}
                  label="Customer"
                  margin="none"
                  variant="standard"
                />
              )}
            />
          </div>
        }
        {
          false && agreementList &&
          <div className={`${selected?.length > 2 ? 'col-lg-6' : 'col-lg-3'}`}>
            <Autocomplete
              id="size-small-standard-multi"
              size="small"
              multiple
              limitTags={`${selected?.length > 2 ? 4 : 1}`}
              options={agreementList}
              disableCloseOnSelect
              defaultValue={selected}
              onChange={(e, item) => {
                if (item) {
                  setSelected(item)
                } else {
                  setSelected([])
                }
              }}
              getOptionLabel={(option) => option.agrement_info_no}
              // renderOption={(props, option, { selected }) => (
              //   <li {...props}>
              //     <Checkbox
              //       icon={icon}
              //       checkedIcon={checkedIcon}
              //       style={{ marginRight: 8 }}
              //       checked={selected}
              //     />
              //     {option.agrement_info_no}
              //   </li>
              // )}
              // style={{ width: 500 }}
              renderInput={(params) => (
                <TextField margin="none" {...params} label="Agreements" placeholder="Agreements" />
              )}
            />
          </div>
        }
      </div>
    </div>

  );
}