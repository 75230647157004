/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { directUSAPICall } from "../../../../_metronic/helpers/components/utils"

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '60%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: '20px'
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}));



export default function Step5({
  setDescription,
  description,
  values,
  isDisable
}) {
  const classes = useStyles();
  const [notes, setNotes] = React.useState([]);
  // const [description, setDescription] = useState('')
  useEffect(() => {
    if (values && values.id) {
      getNotes(values.id)
    }
  }, [values])

  const getNotes = async (id) => {
    let options = {
      limit: -1,
      sort: '-id',
      fields: ['description,id'],
      filter: {
        selected_id: {
          _eq: id,
        },
      },
    }
    const result = await directUSAPICall().items('notes').readMany(options);
    if (result.data && result.data.length > 0) {
      setNotes(result.data);
    } else {
      setNotes([])
    }

  }

  const deleteNotes = async (id) => {
    if (id) {
      await directUSAPICall().items('notes').deleteOne(id);
      getNotes(values.id)
    }
  }

  return (
    <div className="card card-custom gutter-b">
      <div className='card-body box-shadow notes-sec'>
        <div className="form-group row">
          <label className='form-label fw-bolder text-dark fs-6'>Notes</label>
          <div className='col-lg-12'>
            <TextField
              id="standard-multiline-flexible"
              //  label="Notes"
              multiline
              maxRows="10"
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}

              rows={6}
              value={description}
              onChange={(e) => {
                const { value } = e.target;
                console.log("valueeeeeee", value)
                setDescription(value)
              }
              }
              className={classes.textField}
            //  margin="normal"
            />
          </div>
        </div>
        <div className='card-body pt-2'>
          {
            notes?.length > 0 ? notes.map((item, key) => (
              <div key={key} className='d-flex align-items-center mb-8'>
                {/* begin::Bullet */}
                <span className='bullet bullet-vertical h-40px bg-success'></span>
                {/* end::Bullet */}
                {/* begin::Checkbox */}
                <div className='form-check form-check-custom form-check-solid mx-5'>
                </div>
                {/* end::Checkbox */}
                {/* begin::Description */}
                <div className='flex-grow-1 bigfont'>
                  {item.description}
                </div>
                {/* end::Description */}
                <span className='badge badge-light-success fs-8 fw-bolder'>

                  {/* <i className="fas fa-edit" onClick={() => edit(item)} /> */}
                  <i className="fas fa-trash-alt" onClick={() => deleteNotes(item.id)} />
                </span>
              </div>
            )) :
              <div className='d-flex align-items-center mb-8'>
                No data found
                          </div>
          }
        </div>
      </div>

    </div>
  )
}
