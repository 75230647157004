import axios from 'axios'
import { directUSAPIToken } from '../../../../_metronic/helpers/components/utils';
import {API_URL} from '../../../../_metronic/helpers/components/constant'


export const ASSETTYPES = `${API_URL}/items/asset_type`
const headers = {
  'Authorization': `Bearer ${directUSAPIToken()}`
}
// Server should return AuthModel
export function AssetTypesList(fields=null) {
  let param = "?sort=asset_name&fields=id,asset_name"
  param = fields ? param+fields: param;
  return axios.get(`${ASSETTYPES}${param}`, {
    params: {},
    headers
  })
}

export function Create(param) {
  return axios.post(ASSETTYPES, { param }, { headers })
}