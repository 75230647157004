/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
// import AutoComplete from "./autoComplete";
import { AGGREMENTDETAILSFIELDS } from "../../../../_metronic/helpers/components/constant";
import { directUSAPICall } from "../../../../_metronic/helpers/components/utils"
import Autocomplete from '@mui/material/Autocomplete';
import moment from "moment";

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '60%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: '20px'
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}));

export default function Step1(
  {
    agreementList,
    values,
    setValues,
    agreementData,
    setAgreementData,
    urlParams,
    isDisable,
    showFields,
  }) {
  const classes = useStyles();
  const [selectedAgreementData, setSelectedAgreementData] = useState()
 

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  useEffect(() => {
    if (selectedAgreementData) {
      getAgreementData(selectedAgreementData)
    }
  }, [selectedAgreementData, urlParams])

  const getAgreementData = async (id) => {
    // let queryParam = `/${id}?fields=*.*.`

    const data = await directUSAPICall().items('aggrement').readOne(id, { fields: [AGGREMENTDETAILSFIELDS] });
    if (data && data.id) {
      let agData = data

      if (data.partner) {
        agData.partnerid = data.partner.id
        agData.partner = data.partner.partner_name + ' - ' + data.partner.partner_org_no
      }
      if (data.client) {
        agData.clientid = data.client.id
        agData.client = data.client.name + ' - ' + data.client.clients_org_no
      }
      if (data.founding_partner) {
        agData.supplierid = data.founding_partner.id || ''
        agData.founding_partner = (data?.founding_partner.supplier_name ? data?.founding_partner?.supplier_name : '') + '' + data.founding_partner?.supplier_org_no;
      }
      agData.lease_start_date = moment(data.lease_start_date).format('YYYY-MM-DD')
      agData.original_sign_date = data.original_sign_date ? moment(data.original_sign_date).format('YYYY-MM-DD') : null
      agData.eol_date = data.eol_date ? moment(data.eol_date).format('YYYY-MM-DD') : null

      // setAgreementValues(agData)
      values.aggrement = agData;
      setAgreementData(agData)
      // console.log("ag dataaa", agData)
      setValues({ ...values })

    } else {
      // setValues({})
    }
  }

  return (
    <div className="card card-custom gutter-b">
      <div className='card-body box-shadow'>
        <div className="form-group row">
          <div className='col-lg-6'>
            <Autocomplete
              id="size-small-standard"
              value={agreementData.agrement_info_no ? agreementList.find(v => v.agrement_info_no === agreementData.agrement_info_no) || {} : null}
              getOptionLabel={((option) => option.agrement_info_no + " - " + option.heading) || {}}
              options={agreementList}
              onChange={(e, option) => {
                if (option) {
                  setSelectedAgreementData(option.id)
                }
              }}
              disabled={isDisable ? isDisable : false}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant={isDisable ? "filled" : "standard"}
                  label="Agreement Ref"
                  margin="normal"
                />
              )}
            />
          </div>
          {
            showFields ?
              <div className='col-lg-6'>
                <TextField
                  label="Financier"
                  id="Financier"
                  className={classes.textField}
                  value={agreementData.founding_partner}
                  // onChange={handleChange('name')}
                  variant="filled"
                  margin="normal"
                  InputProps={{
                    disabled: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div> : null
          }
          <div className='col-lg-6'>
            <TextField
              id="Partner"
              label="Partner"
              className={classes.textField}
              value={agreementData.partner}
              // onChange={handleChange('name')}
              variant="filled"
              margin="normal"
              InputProps={{
                disabled: true
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div><div className='col-lg-6'>
            <TextField
              label="Type of financing"
              id="Type of financing"
              className={classes.textField}
              value={agreementData.type_of_leasing}
              variant="filled"
              margin="normal"
              InputProps={{
                disabled: true
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div><div className='col-lg-6'>
            <TextField
              label="Client"
              id="Client"
              className={classes.textField}
              value={agreementData.client}
              variant="filled"
              margin="normal"
              InputProps={{
                disabled: true
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          {/* <div className='col-lg-6'>
            <TextField
              id="Type of Agreement"
              label="Type of Agreement"
              className={classes.textField}
              value={agreementData.typeofaggrements}
              variant="filled"
              margin="normal"
              InputProps={{
                disabled: true
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div> */}
          <div className='col-lg-6'>
            <TextField
              label="Start Date"
              id="Start Date"
              className={classes.textField}
              value={agreementData.lease_start_date}
              variant="filled"
              margin="normal"
              InputProps={{
                disabled: true
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div><div className='col-lg-6'>
            <TextField
              label="No of months leasing"
              id="No of months leasing"
              className={classes.textField}
              value={agreementData.no_of_month_lease}
              variant="filled"
              margin="normal"
              InputProps={{
                disabled: true
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              label="Lease end"
              id="End Date"
              className={classes.textField}
              value={agreementData.eol_date}
              variant="filled"
              margin="normal"
              InputProps={{
                disabled: true
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              label="Agreement Status"
              id="Status"
              className={classes.textField}
              value={agreementData.ag_status}
              variant="filled"
              margin="normal"
              InputProps={{
                disabled: true
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              label="Invoicing to"
              id="Invoicing to"
              className={classes.textField}
              value={agreementData.invoicing_to}
              variant="filled"
              margin="normal"
              InputProps={{
                disabled: true
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              label="Payment interval"
              id="Payment interval"
              className={classes.textField}
              value={agreementData.billing_period}
              variant="filled"
              margin="normal"
              InputProps={{
                disabled: true
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}

              id="supplier_invoice_number"
              name="supplier_invoice_number"
              label="Supplier invoice number"
              className={classes.textField}
              value={values.supplier_invoice_number}
              onChange={handleChange('supplier_invoice_number')}
              margin="normal"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
