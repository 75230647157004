import axios from 'axios'
import { directUSAPIToken } from '../../../../_metronic/helpers/components/utils';
import {API_URL} from '../../../../_metronic/helpers/components/constant'


export const BANK = `${API_URL}/items/bank`
const headers = {
  'Authorization': `Bearer ${directUSAPIToken()}`
}
// Server should return AuthModel
export function getbanksList(queryParam) {
  // console.log("filedss", fields)
  // let param = "?fields="
  // param = fields ? param+fields: param;
  return axios.get(`${BANK}${queryParam}`, {
    params: {},
    headers
  })
}

export function Create(param) {
  // console.log("pararaam", param)
  return axios.post(BANK, param, { headers })
}

export function UpdateBank(param, id) {
  delete param.id
  return axios.patch(`${BANK}/${id}`, param, { headers })
}

export function bankDetail(queryParam) {
  return axios.get(`${BANK}${queryParam}`, {
    params: {},
    headers
  })
}