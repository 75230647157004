/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { DropzoneAreaBase } from 'material-ui-dropzone'
import FileLists from '../components/filesLists';
import { fileupload } from "../redux/AgreementCrud"

export default function Step3({
  values,
  setNewUploadedFils,
  isDisable
}) {
  const [fileObjects, setFileObjects] = useState([]);
  // console.log("values", values)
  const handleAdd = newFiles => {
    let formData = new FormData();
    setFileObjects([].concat(fileObjects, newFiles));
    formData.append("file", newFiles[0].file);
    fileupload(formData)
      .then(({ data: { data } }) => {
        setNewUploadedFils((prev) => [...prev, { directus_files_id: { id: data.id } }]);
        // setValues({...values})
        // if (data && data.length > 0) {
        //     setAgreementList(data)
        //     setLoading(false)
        // } else {
        //     setLoading(false)
        //     setAgreementList([])
        // }
      })
      .catch(() => {
        // setAgreementList([])
        // setLoading(false)
        //   setStatus('The login detail is incorrect')
      })
    // newFiles = newFiles.filter(file => !files.find(f => f.data === file.data));
  };



  return (
    <div className="card card-custom gutter-b">
      <div className='card-body box-shadow  notes-sec'>
        {/* <form
          autoComplete="off"
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        > */}
        {
          !isDisable ? <div className="form-group row">
            <div className='col-lg-12'>
              <label
                htmlFor='Upload documents'
                className='form-label fs-6 fw-bolder mb-3'
              >
                Upload documents
                      </label>
              {/* <DropzoneArea
              open={open}
              showAlerts={false}
              filesLimit={5}
              // onChange
              // onSave={this.handleSave.bind(this)}
              acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'application/pdf']}
              showPreviews={true}
              maxFileSize={5000000}
              onClose={() => setOpen(false)}
            /> */}
              <div className="uploaddocument">
                <DropzoneAreaBase
                  onAdd={handleAdd}
                  dropzoneText="Drag and drop a document here or click"
                  // showPreviews={true}
                  // showFileNamesInPreview={true}
                  fileObjects={fileObjects}
                  clearOnUnmount={true}
                  // showFileNames={true}
                  onDelete={deleteFileObj => {
                    console.log('onDelete', deleteFileObj);
                  }}
                />
              </div>
            </div>

          </div> : null
        }
        <FileLists isDisable={isDisable} files={values && values.files} className='card-xl-stretch mb-5 mb-xl-8' />
        {/* </form> */}
      </div>
    </div>
  )
}
