/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
// import AutoComplete from "./autoComplete";
import { AGGREMENTDETAILSFIELDS, PRODUCTCATEGORIES } from "../../../../_metronic/helpers/components/constant";
import { directUSAPICall } from "../../../../_metronic/helpers/components/utils"
import Autocomplete from '@mui/material/Autocomplete';
import moment from "moment";
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '60%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: '20px'
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}));

export default function Step1(
  {
    values,
    setValues,
    assetTypes,
    suppliers,
    urlParams,
    isDisable,
    showFields,
  }) {
  const classes = useStyles();
  const [selectedAgreementData, setSelectedAgreementData] = useState()


  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  return (
    <div className="card card-custom gutter-b">
      <div className='card-body box-shadow'>
        <div className="form-group row">

          <div className='col-lg-6'>
            <TextField
              value={values.equipment ? assetTypes.find(v => v === values.equipment) || {} : null}
              label="Equiplment"
              id="equipment"
              name="equipment"
              select
              InputProps={{
                disabled: isDisable
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant={isDisable ? "filled" : "standard"}
              className={classes.textField}
              // value={values.equipment}
              onChange={handleChange('equipment')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
            >
              {PRODUCTCATEGORIES.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div className='col-lg-6'>
            <Autocomplete
              value={values.supplier ? suppliers.find(v => v.id === values.supplier) || {} : null}
              name={'supplier'}
              loading={values.supplier}
              id={'supplier'}
              // defaultValue={suppliersValues.find(v => v.id === )}
              getOptionLabel={(option) => option && (option.supplier_name + " - " + option.supplier_org_no)}
              options={suppliers}
              onChange={(e, option) => {
                if (option) {
                  setValues({ ...values, supplier: option.id })
                }
              }}
              disabled={isDisable ? isDisable : false}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  error={!values.supplier ? true : false}
                  label="Supplier"
                  margin="normal"
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  variant={isDisable ? "filled" : "standard"}
                />
              )}
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}

              id="desc"
              name="description"
              label="Description"
              className={classes.textField}
              value={values.description}
              onChange={handleChange('description')}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}

              id="quanat"
              name="quantity"
              label="Quantity"
              className={classes.textField}
              value={values.quantity}
              onChange={handleChange('quantity')}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}

              id="unit"
              name="unit_price"
              label="Unit price"
              className={classes.textField}
              value={values.unit_price}
              onChange={handleChange('unit_price')}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              variant={isDisable ? "filled" : "standard"}

              InputProps={{
                disabled: isDisable
              }}

              label="Sum assets value"
              id="Sum asset Value"
              className={classes.textField}
              name={'sum_purchase_value'}
              value={values.sum_purchase_value}
              onChange={handleChange('sum_purchase_value')}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              InputProps={{ inputProps: { min: "1999-05-01", max: "2070-05-04" } }}
              variant={isDisable ? "filled" : "standard"}

              label="Date of purchase"
              id="Date of purchase"
              name={'date_of_purchase'}
              className={classes.textField}
              value={values.date_of_purchase}
              type="date"
              margin="normal"
              format="YYYY-MM-DD"
              onChange={handleChange('date_of_purchase')}

              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
